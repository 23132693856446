import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  CardActions,
  Button,
  Grid,
  CircularProgress,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import * as uuid from 'uuid'
import AlertComponent, {
  AlertData,
} from '../../../../../../components/utilities/AlertComponent'
import Toaster from '../../../../../../config/Toaster'
import { BaseResponse } from '../../../../../../interfaces/ApiResponse'
import useStore from '../../../../../../store'
import { DEFAULT_CURRENCY, TIME_SLUG, EMAIL_SERVICES } from '../../../../../../config/Constant'
import { handleUsdPriceChange } from "../handleUSDPriceChangeUtility"

const LayoutEdit = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { id } = useParams()
  const { LAYOUT } = useStore()
  const { updateLayout, fetchLayout } = LAYOUT
  const [alertMessage, setAlertMessage] = useState<AlertData | null>(null)
  const [exchangeRates, setExchangeRates] = useState<any>(null)

  const validationSchema = Yup.object().shape({
    layout_name: Yup.string().required('Layout name is required').matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
    time: Yup.number().required('Layout time is required').typeError('Please enter valid Layout time')
    .positive('Layout time value must be greater than 0'),
    time_slug: Yup.string().required('Layout slug is required'),
    services: Yup.array().min(1, 'At least one service is required').required('Please select at least one service'),
    timeslots: Yup.array().of(
      Yup.object().shape({
        timeslot_id: Yup.number().required(),
        currencies: Yup.array().of(
          Yup.object().shape({
            design_price: Yup.number().nullable().typeError('Please enter valid price').min(0,'Value must be great or equal to 0'),
            coding_price: Yup.number().required().typeError('Please enter valid price')
            .positive('Price value must be greater than 0'),
          }),
        ),
      }),
    ),
  })

  const {
    handleSubmit,
    setError,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      layout_name: '',
      time: 0,
      time_slug: 'Hrs',
      services: [],
      timeslots: [],
    },
  })

  const {
    fields: timeSlotFields,
  } = useFieldArray<any>({
    control,
    name: 'timeslots',
  })

  const fetchLayoutData = async (id: any) => {
    try {
        const resData: any = await fetchLayout(id)
        if (resData.error === false) {
          setValue('layout_name', resData?.data?.layout?.layout_name || '')
          setValue('time', resData.data.layout?.time || 0)
          setValue('time_slug', resData.data?.layout?.time_slug || '')
          const servicesArr = resData?.data?.layout?.services
          setValue("services", EMAIL_SERVICES.filter((service: any) => {
            return servicesArr?.includes(service?.label);
          }))
          setValue("timeslots", resData?.data?.timeslots)
          const currencyConversionRates = resData?.data?.currency_conversion_rates
          setExchangeRates(currencyConversionRates)
        } else {
          setAlertMessage({severity:'error', message: resData.message, data: resData.data});
          toast.error(resData?.message, Toaster)
        }
      } catch (err: any) {
        console.error(err)
        const errData: BaseResponse = err
        toast.error(errData.message, Toaster)
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }
  }

  const onSubmit = async (_data: any) => {
      try {
      if(_data.timeslots === undefined || _data.timeslots === null || _data.timeslots.length === 0) {
        toast.error("Timeslots values are missing!", Toaster)
         return;
      }  
      const postData = _data
      const resData: BaseResponse = await updateLayout(id, postData);
      if (resData.error === false) {
        navigate("../layouts")
        toast.success(resData?.message, Toaster)
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setAlertMessage({
          severity: 'error',
          message: resData.message,
          data: resData.data,
        })
        toast.error(resData?.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setAlertMessage({
        severity: 'error',
        message: errData.message,
        data: errData.data,
      })
      if (errData.data && errData.data['layout_name']) {
        setError('layout_name', {
          type: 'manual',
          message: errData.data['layout_name'][0],
        })
      }
      toast.error(errData?.message, Toaster)
    }
  }

  useEffect(() => {
    fetchLayoutData(id)
  }, [id])


  console.error(errors)

  return (
    <>
      <Box sx={{ mb: 3 }}>
        {alertMessage ? (
          <AlertComponent
            onClose={() => {
              setAlertMessage(null)
            }}
            severity={alertMessage.severity}
            message={alertMessage.message}
            data={alertMessage.data}
          />
        ) : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Edit Layout
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="layout_name"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      fullWidth
                      id="layout_name"
                      label="Layout Name"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
               
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="time"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      fullWidth
                      id="time"
                      label="Layout Time"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`time_slug`}
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      id={`time_slug`}
                      select
                      label="Time Slug"
                      fullWidth
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      onChange={(e: any) => {
                        field.onChange(e)
                      }}
                      disabled={true}
                    >
                      {TIME_SLUG &&
                        TIME_SLUG.map((slug: any) => (
                          <MenuItem key={uuidValue()} value={slug.name}>
                            {slug.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                  <Controller
                    name="services"
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <Autocomplete
                        disabled
                        multiple
                        {...field}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        options={EMAIL_SERVICES}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                        }}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Services"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
              </Grid>
              <Grid item xs={12}>
                {timeSlotFields ? (
                  timeSlotFields.map((timeslot: any, timeslotIndex: any) => (
                    <Box key={timeslotIndex} sx={{ mb: '10px' }}>
                      <Accordion defaultExpanded={true}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id={`panel1a-header-${timeslotIndex}`}
                          sx={{
                            margin: '0px 0px',
                            bgcolor: '#b3b1b1',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography>
                            {timeslot.time} {timeslot.time_slug} Timeslot
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box sx={{ px: 10, py: 2 }}>
                            <Grid container spacing={2}>
                              {timeslot.currencies.map(
                                (currency: any, currencyIndex: any) => (
                                  <Grid
                                    sx={{
                                      mb: '4px',
                                      mt: '4px',
                                      alignItems: 'center',
                                    }}
                                    container
                                    spacing={2}
                                    key={currencyIndex}
                                  >
                                    <Grid item xs={3}>
                                      <label
                                        htmlFor={`timeslots.${timeslotIndex}.currencies.${currencyIndex}.id`}
                                      >
                                        {currency.name}
                                      </label>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`timeslots.${timeslotIndex}.currencies.${currencyIndex}.design_price`}
                                        control={control}
                                        render={({
                                          field,
                                          fieldState,
                                        }: any) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            type="number"
                                            id={`timeslots.${timeslotIndex}_${currencyIndex}_design_price_${currency.name}`}
                                            label="Design Price"
                                            variant="outlined"
                                            onChange={(e: any, newVal: any) => {
                                              field.onChange(e);
                                              if(currency.name === DEFAULT_CURRENCY) {
                                                handleUsdPriceChange(e, timeslotIndex, currencyIndex, 'design_price', setValue, trigger, exchangeRates) 
                                              }
                                            }}
                                            error={fieldState.invalid}
                                            helperText={
                                              fieldState?.error?.message
                                            }
                                            InputProps={{
                                              inputProps: { min: 0 }, 
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Controller
                                        name={`timeslots.${timeslotIndex}.currencies.${currencyIndex}.coding_price`}
                                        control={control}
                                        render={({
                                          field,
                                          fieldState,
                                        }: any) => (
                                          <TextField
                                            {...field}
                                            fullWidth
                                            type="number"
                                            id={`timeslots.${timeslotIndex}_coding_price_${currencyIndex}_${currency.name}`}
                                            label="Coding Price"
                                            variant="outlined"
                                            onChange={(e: any) => {
                                              field.onChange(e);
                                              if(currency.name === DEFAULT_CURRENCY) {
                                                handleUsdPriceChange(e, timeslotIndex, currencyIndex, 'coding_price', setValue, trigger, exchangeRates) 
                                              }
                                            }}
                                            error={fieldState.invalid}
                                            helperText={
                                              fieldState?.error?.message
                                            }
                                            InputProps={{
                                              inputProps: { min: 0 }, 
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                ),
                              )}
                            </Grid>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ))
                ) : (
                  <CircularProgress /> // or any loading indicator
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={(e) => navigate(-1)}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default LayoutEdit
