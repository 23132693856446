import React from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { Box, Checkbox, FormControlLabel, FormLabel } from "@mui/material";
import * as uuid from "uuid";
import { RenderTree } from "./PermissionInterface";


interface MyComponentProps {
  permissions: RenderTree[]; // Replace 'number[]' with the actual type of selectedIds
  setPermissions: React.Dispatch<React.SetStateAction<RenderTree[]>>; // Type for setState function
  selected: string[]; // Replace 'number[]' with the actual type of selectedIds
  setSelected: React.Dispatch<React.SetStateAction<string[]>>; // Type for setState function
}

const PermissionTreeView: React.FC<MyComponentProps> = ({permissions, setPermissions, selected, setSelected}) => {
  const uuidValue = () => uuid.v4();

  const areAllChildsChecked = (arrayX: any) => {
    const arrayS = arrayX.children.filter((e:any) => e.checked)
    return arrayX.children.length === arrayS.length
  } 

  const updateSelectIdArray = (permissionArray: RenderTree[]) => {
    try{
      const updatedArray: string[] = [];
      [...permissionArray].forEach((pr: any) => {
        pr?.children.forEach((item:any) => {
          if(item.checked) { updatedArray.push(item.id) };
        });
      })
      setSelected(updatedArray);
    }catch(err: any){}
  }

  const handleChildToggle = (event: React.SyntheticEvent, parentId: string) => {
    try{
      event.stopPropagation();
      const updatedPermission = [...permissions].map((permission:any) => {
        if(permission.id === parentId){
          permission.children.map((e:any) => {
            e.checked = !permission.checked;
            return e;
          })
          permission.checked = !permission.checked;
        }
          return permission;
      })
      updateSelectIdArray(updatedPermission);
      setPermissions(updatedPermission);
    }catch(err: any){}
  };
  
  const handleToggle = (event: React.SyntheticEvent, nodeId: string, parentId: any) => {
    try{
      const updatedPermission = [...permissions].map((permission:any) => {
        const childIds: string[] = [];
        const selectedChildIds: string[] = [...selected];
        permission.children.map((e:any) => {
            if(e.id === nodeId){
              e.checked = !e.checked;
              selectedChildIds.push(String(e.id))
            }else{
              selectedChildIds.slice(selectedChildIds.indexOf(e.id), 1)
            }
              childIds.push(String(e.id));
              return e;
            })
            permission.checked = areAllChildsChecked(permission);

          return permission;
      });

      updateSelectIdArray(updatedPermission);
      setPermissions(updatedPermission);
    }catch(err: any){

    }
  };
          console.log(permissions, "permissions")
  
  const renderTree = (nodes: RenderTree[], parentId: string|null = null) =>
    nodes.map((node) => {
      if (node.children) {
        return (
          <TreeItem
            key={uuidValue()}
            nodeId={node.id}
            label={<>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Checkbox checked={node?.checked} onClick={(e) => handleChildToggle(e, node.id)} />
              <FormLabel component="legend" sx={{mt: 1}}>{node.id}</FormLabel>
              </Box>
            </>
          }
          >
            {renderTree(node.children, node.id )}
          </TreeItem>
        );
      } else {
        return (
          <TreeItem
            key={uuidValue()}
            nodeId={node.id}
            label={
              <FormControlLabel
                label={node.name}
                control={<Checkbox checked={node?.checked} onClick={(e) => handleToggle(e, node.id, parentId)} />}
              />
            }
          ></TreeItem>
        );
      }
  });

  return (<>
  <h3>Permissions</h3>
    <TreeView
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultSelected={selected}
      disabledItemsFocusable={false}
      selected={selected}
      multiSelect
      sx={{ flexGrow: 1, maxWidth: 400, overflowY: "auto" }}
    >
      {renderTree(permissions)}
    </TreeView>
    </>
  );
}


export default PermissionTreeView;