import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  CardActions,
  Button,
  Grid,
  Autocomplete,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import AlertComponent, {
  AlertData,
} from '../../../../../../components/utilities/AlertComponent'
import Toaster from '../../../../../../config/Toaster'
import { BaseResponse } from '../../../../../../interfaces/ApiResponse'
import useStore from '../../../../../../store'

const DefaultPointCutoffEdit = observer(() => {
  const navigate = useNavigate()
  const { id } = useParams();
  const [alertMessage, setAlertMessage] = useState<AlertData | null>(null)
  const [userMenuItems, setUserMenuItems] = useState<any>([])
  const { DEFAULT_POINT_CUTOFF } = useStore()
  const { updateDefaultPointCutoff, fetchDefaultPointCutoff } = DEFAULT_POINT_CUTOFF

  const validationSchema = Yup.object().shape({
    cut_off_point: Yup.number().required('cutoff points is required').typeError('Please enter valid points').positive('Points value must be positive'),
    user: Yup.object({
      label: Yup.string().required('User is required'),
      id: Yup.number().nullable(),
    }).required('User is required'),
  })

  const {
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      cut_off_point: 0,
      user: { label: '', id: null },
    },
  })

  const setInitialFormValues = async (_cutoffData: any) => {
    try{
      await setValue('cut_off_point', _cutoffData?.cut_off_point)
      await setValue('user', _cutoffData?.user)
    }catch(err: any){
    }

    return true
  }
  const fetchCutoff = async (_id: string | undefined) => {
    try {
      const resData: any = await fetchDefaultPointCutoff(id)
      if (resData.error === false) {
        setUserMenuItems(resData?.data?.users)

        await setInitialFormValues(resData?.data)
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      toast.error(errData.message, Toaster)

    }
  }

  const onSubmit = async (_data: any) => {
    const postData = {
      cut_off_point: _data.cut_off_point,
      user_id: _data.user.id,
    }
    try {
      const resData: BaseResponse = await updateDefaultPointCutoff(id,postData)
      if (resData.error === false) {
        toast.success(resData?.message, Toaster)
        navigate("../default-point-cutoffs")
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setAlertMessage({
          severity: 'error',
          message: resData.message,
          data: resData.data,
        })
        toast.error(resData?.message, Toaster)
      }
    } catch (err : any) {
      const errData: BaseResponse = err
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setAlertMessage({
        severity: 'error',
        message: errData.message,
        data: errData.data,
      })
      if(errData.data && errData.data['user_id']){
        setError("user", {
          type: "manual",
          message: errData.data['user_id'][0],
        });
      }
      toast.error(errData?.message, Toaster)
    }
  }


  useEffect(() => {
    fetchCutoff(id)
  }, [id])

  console.error(errors);

  return (
    <>
      <Box sx={{ mb: 3 }}>
        {alertMessage ? (
          <AlertComponent
            onClose={() => {
              setAlertMessage(null)
            }}
            severity={alertMessage.severity}
            message={alertMessage.message}
            data={alertMessage.data}
          />
        ) : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Edit Cutoff
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="user"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={userMenuItems ?? []}
                      isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="User"
                          variant="outlined"
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="cut_off_point"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      fullWidth
                      id="name"
                      type="text"
                      label="Cutoff"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      {...field}
                      InputProps={{
                        inputProps: { min: 0 }, 
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={(e) => navigate(-1)}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default DefaultPointCutoffEdit
