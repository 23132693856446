import React, { useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import EditIcon from '../../../../assets/images/note.png'
import Box from '@mui/material/Box'
import ClientDelete from './ClientDelete'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import VisibilityRoundedIcon from '../../../../assets/images/files.png';
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../components/AgGridWrapper/RecordPerPage'
import AllPermissionList from '../../../../config/AllPermissionList'
import { Stack } from '@mui/material'

const ClientList = observer(() => {
  const { CLIENT, CLIENT_ORDER, AUTH } = useStore()
  const navigate = useNavigate()
  const { setupGrid, setPageSize, perPage } = CLIENT
  const { setCurrentClientValue } = CLIENT_ORDER
	const {permissions} = AUTH
  const [deleteModal, setDeleteModal] = useState<boolean>(false)

  // const changeStatusHandle = async (_data:any) => {
  //   try {
  //     const resData: any = await changeStatus(_data)
  //     if (resData.error === false) {
  //       toast.success(resData.message, Toaster)
  //     } else {
  //       toast.error(resData.message, Toaster)
  //     }
  //   } catch (err: any) {
  //     console.error(err)
  //     toast.error(err.message, Toaster)
  //   }
	// }

  const actionColumnRenderer = (params: any) => {
    return (
      <>
        <Tooltip title="Edit" sx={{ display : (permissions && permissions.indexOf(AllPermissionList.EDIT_CLIENT) >= 0) ? "inline-flex" : "none" }}>
          <IconButton
            onClick={() => {
              navigate('../edit/' + params?.data?.enc_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
        {/* commented for now as need to update in apis as well to make this properly working */}
        {/* <Tooltip title="Info">
          <IconButton onClick={() => {
             setCurrentClientValue({ client_name : params?.data?.client_name })
             navigate('../orders/' + params?.data?.enc_id)
            }}
          >
             <img src={VisibilityRoundedIcon} alt="edit" className='editicon' />
           </IconButton>
        </Tooltip> */}
      </>
    )
  }

  // const statusColumnRenderer = (params: any) => {
  //   const label = { inputProps: { 'aria-label': `Status Switch` } }
  //   return (
  //     <>
  //       <Switch 
  //         {...label}
  //          defaultChecked={params.value === 1}
  //          onChange= {() => changeStatusHandle({id:params.data?.enc_id,status:params.value === 1 ? 0 : 1})}
  //          />
  //     </>
  //   )
  // }

  const [columnDefs, ] = useState<any>([
    // {
    //   headerName: 'Client Name',
    //   field: 'client_name',
    // },
    {
      headerName: 'Company Name',
      field: 'company_name',
    },
    {
      headerName: 'Phone',
      field: 'phone',
    },
    {
      headerName: 'Client Type',
      field: 'client_type',
    },
    {
      headerName: 'GEO',
      field: 'geo_name',
    },
    { 
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter', 
      // extra filters params
      floatingFilterComponentParams: {
        suppressFilterButton: false, 
      },
      pinned: 'right'
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable : false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      width: 70,
      minWidth: 120,
      pinned: 'right'
    },
    // {
    //   headerName: 'Status',
    //   field: 'status',
    //   sortable : false,
    //   filter: false,
    //   cellRenderer: statusColumnRenderer,
    // }
  ])

  // const openDeleteModal: any = (data: any) => {
  //   setDeleteValues(data)
  //   setDeleteModal(true)
  // }

  const closeDeleteModal = () => {
    setDeleteModal(false)
  }
  
  return (
    <>
    <Stack
      direction="row"
      justifyContent="space-between"
      alignContent="center"
      spacing={2}
      sx={{ mb: '20px' }}
    > 
     <h1>Clients</h1>
      <Box sx={{ mb: '20px' }}>
        <Button
          onClick={() => navigate('../create')}
          variant="contained"
          startIcon={<AddCircleIcon />}
          sx={{ display : (permissions && permissions.indexOf(AllPermissionList.ADD_CLIENT) >= 0) ? "inline-flex" : "none" }}
        >
          NEW CLIENT
        </Button>
      </Box>
      </Stack>

      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
      />
      <Box className="record-per-page-filter" sx={{ mt: '20px', ml: '-10px' }}>
        <RecordPerPage
          style={{ width: "150px" }}
          defaultValue={`${perPage}`}
          onChange={(e:any)=> setPageSize(e.target.value)}
        />
      </Box>
      <ClientDelete visible={deleteModal} close={closeDeleteModal} />
    </>
  )
})

export default ClientList
