import { yupResolver } from '@hookform/resolvers/yup'
import {
  Card,
  Box,
  CardContent,
  Typography,
  TextField,
  CardActions,
  Button,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Toaster from '../../../../../config/Toaster'
import { BaseResponse } from '../../../../../interfaces/ApiResponse'
import useStore from '../../../../../store'
import * as Yup from 'yup'
import * as uuid from 'uuid'
import { observer } from 'mobx-react'
import AlertComponent, {
  AlertData,
} from '../../../../../components/utilities/AlertComponent'
import {
  COUPON_RESTRICTION_TYPES,
  COUPON_VALUE_TYPES,
} from '../../../../../config/Constant'

const validationSchema = Yup.object().shape({
  coupon_code: Yup.string()
    .required('Coupon code is required')
    .min(2, 'Coupon code must be at least 2 characters long')
    .matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
  value_type: Yup.number()
    .required('Value type is required')
    .oneOf([0, 1], 'Invalid value type'),
  value: Yup.number()
    .required('Value is required')
    .typeError('Please enter valid value')
    .test('is-valid-value', 'Invalid value', function (value) {
      const { value_type } = this.parent
      if (value_type === 0) {
        return (
          value > 0 ||
          this.createError({
            message: 'Value must be greater than zero',
          })
        )
      } else if (value_type === 1) {

        if(value <= 0){
          return this.createError({
            message: 'Value must be greater than zero',
          })
        }
        return (
          (value <= 100) ||
          this.createError({
            message: 'Value must be less or equal to 100 as you have selected the type percentage',
          })
        )
      }
      return true
    }),
    can_use: Yup.number().required('can use is required').typeError('Please enter valid number').positive('Value must be greater than zero'),
  status: Yup.number()
    .required('Status is required')
    .oneOf([0, 1], 'Invalid status'),
  restriction_type: Yup.number()
    .required('Restriction type is required')
    .oneOf([0, 1], 'Invalid restriction type'),
  allow_gmail_yahoo: Yup.boolean().test(
      'is-required',
      'Allow Gmail Yahoo is required',
      function (value) {
        const { restriction_type } = this.parent.restriction_type
        if (restriction_type === 1) {
          return value === true
        }
        return true
      },
    ),
    remarks : Yup.string().required('Remarks is required'),
})

const CouponEdit = observer(() => {
    const navigate = useNavigate()
    const uuidValue = () => uuid.v4()
    const { id } = useParams()
    const { COUPON } = useStore();

    const { fetchCoupon, updateCoupon} = COUPON;
    const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
    
    const {
      handleSubmit,
      setError,
      setValue,
      control,
      watch,
      trigger,
    } = useForm({
      resolver: yupResolver(validationSchema),
      mode: "onTouched",
      defaultValues: {
        coupon_code: '',
        value_type: 0,
        value: 0,
        can_use: 0,
        status: 1,
        restriction_type: 0,
        allow_gmail_yahoo: false,
        remarks: '',
      },
    });
  
    const watchRestrictionType = watch('restriction_type')
  
    const onSubmit = async (_data: any) => {
      try {
        const resData: BaseResponse = await updateCoupon(id, _data);
          if(resData.error === false){
            toast.success(resData?.message, Toaster)
            navigate("../")
          }else{
            window.scrollTo({ top: 0, behavior: 'smooth' })
            setAlertMessage({
              severity: 'error',
              message: resData.message,
              data: resData.data,
            })
            toast.error(resData?.message, Toaster)
          }
        } catch(err:any){
          console.error(err)
          const errData: BaseResponse = err;
          if(errData.data && errData.data['coupon_code']){
            setError("coupon_code", {
              type: "manual",
              message: errData.data['coupon_code'][0],
            });
          }
          toast.error(errData?.message, Toaster);
        }
    };
  
    const fetchCouponData = async (_id: string | undefined) => {
      try {
        const resData: any = await fetchCoupon(id)
        if (resData.error === false) {
          await setValue('coupon_code', resData.data.coupon?.coupon_code || '')
          await setValue('value', resData.data.coupon?.value || 0)
          await setValue('value_type', resData.data.coupon?.value_type || 0)
          await setValue('can_use', resData.data.coupon?.can_use || 0)
          await setValue('restriction_type', resData.data.coupon?.restriction_type || 0)
          await setValue('allow_gmail_yahoo', resData.data.coupon?.allow_gmail_yahoo || 0)
          await setValue('status', resData.data.coupon?.status || 0)
        } else {
          setAlertMessage({severity:'error', message: resData.message, data: resData.data});
           toast.error(resData?.message, Toaster)
        }
      } catch (err: any) {
        console.error(err)
        const errData: BaseResponse = err
        toast.error(errData.message, Toaster)
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }
    }
  
    useEffect(() => {
      fetchCouponData(id)
    }, [])
    
    return (
      <>
      <Box sx={{ mb: 3 }}>
        {alertMessage ? (
          <AlertComponent
            onClose={() => {
              setAlertMessage(null)
            }}
            severity={alertMessage.severity}
            message={alertMessage.message}
            data={alertMessage.data}
          />
        ) : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Edit Coupon
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="coupon_code"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      fullWidth
                      id="coupon_code"
                      label="Coupon Code"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      {...field}
                      onChange={(e) => field.onChange(e.target.value.toUpperCase().replace(/\s/g, ''))}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="value_type"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      id="value_type"
                      select
                      label="Value Type"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e)
                        trigger(`value`)
                      }}
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select Value Type
                      </MenuItem>
                      {COUPON_VALUE_TYPES &&
                        COUPON_VALUE_TYPES.map((type: any) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`value`}
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      id={`value`}
                      label="Value"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                      onChange={(e) => {
                        // Limit input to 4 digits 
                        const inputValue = e.target.value.replace(/\D/g, '').slice(0, 4);
                        field.onChange(inputValue);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={`can_use`}
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="text"
                      id={`can_use`}
                      label="Can Use (Times)"
                      variant="outlined"
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="restriction_type"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      id="restriction_type"
                      select
                      label="Restriction Type"
                      fullWidth
                      onChange={(e) => {
                        if(watchRestrictionType === 0){
                          setValue("allow_gmail_yahoo", false)
                          trigger("allow_gmail_yahoo")
                        }
                        field.onChange(e)
                      }}
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select Restriction Type
                      </MenuItem>
                      {COUPON_RESTRICTION_TYPES &&
                        COUPON_RESTRICTION_TYPES.map((type: any) => (
                          <MenuItem key={type.id} value={type.id}>
                            {type.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              {watchRestrictionType === 1 ? (
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Controller
                        name="allow_gmail_yahoo"
                        control={control}
                        render={({ field, fieldState }: any) => (
                          <Checkbox {...field} checked={field.value} />
                        )}
                      />
                    }
                    label="Allow Gmail Yahoo"
                  />
                </Grid>
              ) : (
                <>
                 <Grid item xs={6}></Grid>
                </>
              )}
              <Grid item xs={6}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      id="status"
                      select
                      label="Status"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e)
                      }}
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select Status
                      </MenuItem>
                      {[{ id : 1, label : "Active" }, { id : 0, label : "Inactive" }].map((status: any) => (
                          <MenuItem key={status.id} value={status.id}>
                            {status.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={`remarks`}
                  control={control}
                  defaultValue={''}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      fullWidth
                      id={`remarks`}
                      label="Remarks"
                      variant="outlined"
                      multiline
                      rows={4}
                      error={fieldState.invalid}
                      helperText={
                        fieldState?.error?.message ||
                        fieldState?.error?.label?.message
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={(e) => navigate(-1)}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
    );
})

export default CouponEdit