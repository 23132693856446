import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Box,
  CardContent,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
  CardActions,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import * as uuid from 'uuid'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import QuoteJobPartial from './QuoteJobPartial'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import debounce from '../../../../components/utilities/Debounce'
import AlertComponent, { AlertData } from '../../../../components/utilities/AlertComponent'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import { CURRENCIES, DEBOUNCE_AFTER_CHARS, DEFAULT_CURRENCY, dateTimeFormat } from '../../../../config/Constant'
import LoadingButton from '@mui/lab/LoadingButton'

const QuoteEdit = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { QUOTE, CLIENT, USER } = useStore()
  const { id } = useParams()
  const { fetchQuote, updateQuote, isAttributePriceChanging } = QUOTE

  // Client Email
  const [loading, setLoading] = useState<boolean>(false)
  const [clientMailOptions, setClientMailOptions] = useState<any>([])
  const [clientBillingMailOptions, setClientBillingMailOptions] = useState<any>(
    [],
  )
  const [currencyMenuItems, ] = useState<any>(CURRENCIES)
  const [currency, setCurrency] = useState<any>(DEFAULT_CURRENCY)
  const [reviewByOptions, setReviewByOptions] = useState<any>([])
  const { getClientEmails, getClientBillingEmails } = CLIENT
  const { getUserList } = USER
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)

  const validationSchema = Yup.object().shape({
    is_fof: Yup.boolean(),
    client_id: Yup.number(),
    client_email: Yup.object({
      label: Yup.string()
        .required('Client email is required')
        .email('Invalid email address'),
      id: Yup.string().nullable(),
    }).required('Client email is required'),
    client_billing_email: Yup.object({
      label: Yup.string()
        .required('Billing email is required')
        .email('Invalid email address'),
      id: Yup.string().nullable(),
    }).required('Billing email is required'),
    source: Yup.string().required('Source is required'),
    invoice_pattern: Yup.string().required('Invoice pattern is required'),
    engagement_type: Yup.string().required('Engagement Type is required'),
    currency: Yup.string().required('Currency is required'),
    total_amount: Yup.number().nullable(),
    final_amount: Yup.number().required(
      'Final order amount is required',
    ).typeError('Please enter valid amount').min(0, "Amount shouldn't be less than 0"),
    reviewed_by: Yup.array().nullable(),
    is_custom_amount: Yup.boolean(),
    final_discount: Yup.number()
          .transform((value, originalValue) => (originalValue === "" ? 0 : value)) // Convert "" to null
          .when('is_custom_amount', (isCustomAmount, schema) => {
            return isCustomAmount
              ? schema.nullable() // Not required when custom amount is selected
              : schema.required('Discount is required').min(0, 'Must be at least 0');
          }),
        jobs: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
          .required('Name is required')
          .matches(/^[a-zA-Z0-9 _|:\/-]+$/, 'Special characters are not allowed'),
          job_type: Yup.string().required('Job Type is required'),
          service: Yup.string().required('Service is required'),
          timeslot_id: Yup.string().required('Time slot is required'),
          layout_id: Yup.string().required('Layout is required'),
          addons: Yup.array().min(0, 'Please select at least one addons'), // (min => 0 === nullable) here
          unique_templates: Yup.number()
          .nullable()
          .typeError('Please enter a valid value')
          .test('unique-or-adaptation', function (value) {
            const { adaptation_templates } = this.parent;
        
            // If adaptation_templates has a valid value, skip validation for unique_templates
            if (adaptation_templates > 0) {
              return true; // Skip validation
            }
        
            // Otherwise, unique_templates must be valid
            if (value && value > 0) {
              return true; // Pass validation
            }
        
            return this.createError({
              message: 'Unique templates must be greater than 0',
            });
          }),     
          integrations: Yup.array().min(0, 'Please select at least one integration'),
          other_integration_name: Yup.string().test(
              'check-integration-83',
              'Please provide a name for the other integration',
              function (value) {
                  const { integrations } = this.parent;
                  console.log('Validating other_integration_name: ', { integrations, value });
                  const hasIntegration83 = integrations && integrations.some((item:any) => item.id === 83);
                  if (hasIntegration83 && !value) {
                      return false; // Validation fails
                  }
                  return true; // Validation passes
              }
          ),
          integration_setups: Yup.array().nullable(),
          adaptation_type: Yup.string().nullable(),
          adaptation_templates: Yup.number()
          .nullable()
          .typeError("Please enter valid value")
          .min(0, "Value shouldn't be less than 0")
          .test('conditional-validation', 'Adaptation quantity is required and should be greater than 0', function (
            value: any,
          ) {
            const adaptationType = this.parent.adaptation_type;
            if (adaptationType !== "" && (!value || value < 0 || value === undefined || value === null)) {
              return false
            }
            return true
          }),
          test_report_id: Yup.string().required('Email test report is required'),
          frameworks: Yup.array().min(0, 'Please select at least one framework'),
          design_amount: Yup.number().required('Design amount is required',
          ).typeError('Please enter valid design amount').min(0, "Design amount shouldn't be less than 0"),
          coding_amount: Yup.number().required('Develoment amount is required',
          ).typeError('Please enter valid Develoment amount').min(0, "Develoment amount shouldn't be less than 0"),
          est_delivery_datetime: Yup.string().required('Estimated delivery date time is required'),
        }),
    )
  })

  const {
    watch,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      is_fof: false,
      client_id: 0,
      client_email: { label: '', id: null },
      client_billing_email: { label: '', id: null },
      source: 'Client',
      invoice_pattern: 'Advance',
      engagement_type: 'P2P',
      currency: DEFAULT_CURRENCY,
      reviewed_by: [],
      final_amount: 0,
      final_discount: 0,
      is_custom_amount:false,
      jobs: [],
    },
  })

  const {
    fields: jobsFields,
    append: appendJobsFields,
    remove: removeJobsFields,
  } = useFieldArray<any>({
    control,
    name: 'jobs',
  })

  const setInitialFormValues = async (_quoteData: any) => {
    try{
    setValue('is_fof', _quoteData?.is_fof === 1 ? true : false)
    setValue(
      'client_email',
      _quoteData?.client_email || { id: null, label: '' },
    )
    setValue(
      'client_billing_email',
      _quoteData?.client_billing_email || { id: null, label: '' },
    )
    setValue('source', _quoteData?.source || '')
    setValue('invoice_pattern', _quoteData?.invoice_pattern || '')
    setValue('engagement_type', _quoteData?.engagement_type || '')
    setValue('currency', _quoteData?.currency || '')
    setCurrency(_quoteData?.currency || '')
    setValue('total_amount', (_quoteData?.final_amount - _quoteData?.final_discount) || 0)
    setValue('final_amount', _quoteData?.final_amount || 0)
    setValue('final_discount', _quoteData?.final_discount || 0)
    setValue('is_custom_amount', _quoteData?.is_custom_amount || false)
    setValue('reviewed_by', _quoteData?.reviewed_by || [])

    setReviewByOptions(_quoteData?.reviewed_by)

    // populate jobs details
    if (_quoteData?.jobs && _quoteData?.jobs.length > 0) {
      _quoteData?.jobs.forEach((job: any, index: number) => {
        let jobObj = {
          quote_job_id: job?.quote_job_id || "",
          name: job?.project_name || '',
          job_type: job?.job_type || '',
          service: job?.service || '',
          timeslot_id: job?.timeslot_id || 0,
          layout_id: job?.layout_id || 0,
          addons: job?.addons || [],
          unique_templates: job?.unique_templates || 0,
          integrations: job?.integrations || [],
          other_integration_name: job?.other_integration_name || '',
          integration_setups: job?.integration_setups || [],
          adaptation_type: (job?.adaptation_type === null) ? "" : (job?.adaptation_type === "0" ? "" : job?.adaptation_type),
          adaptation_templates: job?.adaptation_templates || 0,
          test_report_id: job?.test_report_id || '',
          frameworks: job?.frameworks || [],
          est_delivery_datetime: job?.est_delivery_datetime || '',
          design_amount: job?.design_amount || '',
          coding_amount: job?.coding_amount || '',
        }
        appendJobsFields(jobObj)
      })
    }
    }catch(err: any){
      console.error(err);
    }
    return true
  }

  // debounce fetch client email call
  const fetchClientEmailsCall = async (newValue: string) => {
    setLoading(true)
    try {
      if (newValue.length >= DEBOUNCE_AFTER_CHARS) {
        const data: any = { email: newValue }
        const res = await getClientEmails(data)
        await setClientMailOptions(res.data.emails)
        await setLoading(false)
      } else {
        setClientMailOptions([]) // Clear options if input length is less than 3
        await setLoading(false)
      }
    } catch (err) {
      await setLoading(false)
      console.error(err)
    }
  }
  const debouncedHandleInputChange = debounce(fetchClientEmailsCall, 1000)

  // debounce fetch billing email call
  const fetchClientBillingEmailsCall = async (selectedOption: any) => {
    setLoading(true)
    try {
      const data: any = { client_id: selectedOption.client_id }
      const res = await getClientBillingEmails(data)
      setValue('client_billing_email', { label: '', id: null } )
      await setClientBillingMailOptions(res.data.client_billing_emails)
      await setLoading(false)
      if(res?.data?.client_billing_emails.length === 1){
        setValue('client_billing_email', res?.data?.client_billing_emails[0])
      }
    } catch (err) {
      setClientMailOptions([]) // Clear options if input length is less than 3
      await setLoading(false)
      console.error(err)
    }
  }

  // Fetch data from the server when input value changes
  const handleInputClientEmail = async (newValue: any) => {
    debouncedHandleInputChange(newValue)
  }
  const onSelectClientEmail = async (e: any, selectedOption: any) => {
    if (selectedOption) {
      fetchClientBillingEmailsCall(selectedOption)
    }
  }

  const fetchQuoteData = async (_id: string | undefined) => {
    try {
      const resData: any = await fetchQuote(id)
      if (resData.error === false) {
        await setInitialFormValues(resData?.data?.quote)
      } else {
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  // Fetch review by
  const fetchReviewByCall = async (newValue: string) => {
    setLoading(true)
    try {
      if (newValue.length >= DEBOUNCE_AFTER_CHARS) {
        const data: any = { user: newValue }
        const res = await getUserList(data)
        await setReviewByOptions(res.data.reporting_managers)
        await setLoading(false)
      } else {
        setReviewByOptions([]) // Clear options if input length is less than 3
        await setLoading(false)
      }
    } catch (err) {
      await setLoading(false)
      console.error(err)
    }
  }

  const debouncedfetchReviewByCall = debounce(fetchReviewByCall, 1000)
  const handleInputReviewBy = async (newValue: any) => {
    debouncedfetchReviewByCall(newValue)
  }

  useEffect(() => {
    fetchQuoteData(id)
  }, [id])

  const handleAmountChange = (_data: any) => {
    let total_amount = 0;
    _data?.jobs?.forEach((job: any) => {
      total_amount += parseFloat(job?.design_amount) + parseFloat(job?.coding_amount);
    });
    // total amount = design amount + coding amount
    setValue(`total_amount`, Math.floor(total_amount))

    // final amount = total amount - final discount
    let final_discount = _data?.final_discount
    const finalAmount = total_amount - final_discount; // Subtract discount from total amount
    if(_data?.is_custom_amount)
    {
      setValue(`final_amount`, Math.floor(_data?.final_amount))
    }
    else{
      setValue(`final_amount`, Math.floor(finalAmount))
    }
    trigger(`final_amount`)
  };

  // call useEffect once final discount or design/coding amount change
  useEffect(() => {
    handleAmountChange(watch()); 
    // used job 0 to 4 considering max 5 jobs
  }, [
      watch(`final_discount`),
      watch(`jobs.0.design_amount`),
      watch(`jobs.0.coding_amount`),
      watch(`jobs.1.design_amount`),
      watch(`jobs.1.coding_amount`),
      watch(`jobs.2.design_amount`),
      watch(`jobs.2.coding_amount`),
      watch(`jobs.3.design_amount`),
      watch(`jobs.3.coding_amount`),
      watch(`jobs.4.design_amount`),
      watch(`jobs.4.coding_amount`),
  ]);

  const onSubmit = async (_data: any) => {
    try {
      _data.is_fof = _data.is_fof === true ? 1 : 0

      if (_data?.jobs && _data?.jobs.length > 0) {
        Array.from(_data?.jobs).forEach((job: any, index: number) => {
          _data.jobs[index].est_delivery_datetime = dayjs(
            job?.est_delivery_datetime,
          ).format(dateTimeFormat)
        })
      }

      const resData: any = await updateQuote(id, _data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        navigate('../list')
      } else {
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: resData.message, data: resData.data});
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err;
      window.scrollTo({top: 0, behavior: 'smooth' });
      setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      toast.error(err.message, Toaster)
      if (err?.data?.exist_email_ids) {
        toast.error(err?.data?.exist_email_ids, Toaster)
      }
    }
  }

console.error(errors, "errors")
const isCustomAmount = watch('is_custom_amount'); // Watch the is_custom_amount field

  useEffect(() => {
    if (isCustomAmount) {
      // If is_custom_amount is checked, set discount_amount to 0
      setValue('final_discount', 0);
    }
    if(!isCustomAmount){
      const t_amount = getValues('total_amount')  ?? 0;
      setValue('final_amount', t_amount);
    }
  }, [isCustomAmount, setValue, getValues]); // Re-run effect when is_custom_amount changes


  return (
    <>
      <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card sx={{ maxWidth: '100%' }} variant='outlined'>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ mt: 1, mb: 3, px: 2 }}
              >
                Edit Quote
              </Typography>
              <Grid item xs={3} sx={{ mb: '10px', px: 2 }}>
                <FormControlLabel
                  control={
                    <Controller
                      name="is_fof"
                      control={control}
                      defaultValue={false}
                      render={({ field: { value, ref, ...field } }) => (
                        <Checkbox
                          {...field}
                          inputRef={ref}
                          checked={!!value}
                          disableRipple
                        />
                      )}
                    />
                  }
                  label="FOF Order?"
                  labelPlacement="end"
                />
              </Grid>
              <Grid
                sx={{ px: 2 }}
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 3, sm: 6, md: 12 }}
              >
                <Grid item xs={4}>
                  <Controller
                    name="client_email"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        {...field}
                        options={clientMailOptions}
                        onKeyUp={(e: any) =>
                          handleInputClientEmail(e.target.value)
                        }
                        onChange={(e, newVal: any) => {
                          onSelectClientEmail(e, newVal)
                          field.onChange(newVal)
                        }}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.label === value.label
                        }
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Client Email"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="client_billing_email"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        options={clientBillingMailOptions}
                        onChange={(e, newVal) => {
                          field.onChange(newVal)
                        }}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.label === value.label
                        }
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Client Billing Email"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                            helperText={errors.client_billing_email?.message}
                            error={errors.client_billing_email ? true : false}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    name="source"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-select-currency"
                        select
                        label="Source"
                        fullWidth
                        helperText={errors.source?.message}
                        error={errors.source ? true : false}
                      >
                        <MenuItem key={uuidValue()} value={''} disabled>
                          Select Source
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'Client'}>
                          Client
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'Internal'}>
                          Internal
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="invoice_pattern"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-select-currency"
                        select
                        label="Invoice Pattern"
                        fullWidth
                        helperText={errors.invoice_pattern?.message}
                        error={errors.invoice_pattern ? true : false}
                      >
                        <MenuItem key={uuidValue()} value={''} disabled>
                          Select Invoice Pattern
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'Advance'}>
                          Advance
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'Postpaid'}>
                          Postpaid
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    name="engagement_type"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-select-currency"
                        select
                        label="Engagement Type"
                        fullWidth
                        helperText={errors.engagement_type?.message}
                        error={errors.engagement_type ? true : false}
                      >
                        <MenuItem key={uuidValue()} value={''} disabled>
                          Select Engagement Type
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'P2P'}>
                          P2P
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'Dedicated'}>
                          Dedicated
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    name="currency"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="outlined-select-currency"
                        select
                        label="Currency"
                        placeholder="Currency"
                        fullWidth
                        helperText={errors.currency?.message}
                        error={errors.currency ? true : false}
                        onChange={(e: any) => {
                          field.onChange(e)
                          setCurrency(e?.target?.value || "")
                        }}
                      >
                        <MenuItem key={uuidValue()} value={''} disabled>
                          Select Currency
                        </MenuItem>
                          {currencyMenuItems &&
                          currencyMenuItems.map((e: any) => (
                            <MenuItem key={uuidValue()} value={e.label}>
                              {e.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>

                {/****** Box *****/}

                <Grid item xs={12}>
                  {jobsFields.map((item, index) => {
                    return (
                      <QuoteJobPartial
                        key={index}
                        id={index}
                        errors={errors}
                        setValue={setValue}
                        control={control}
                        trigger={trigger}
                        removeJobsFields={removeJobsFields}
                        watch={watch}
                        currency={currency}
                      />
                    )
                  })}
                </Grid>

                {/* <Grid container item justifyContent="center" alignItems="center">
                <Button
                  size="medium"
                  startIcon={<AddCircleIcon />}
                  variant="contained"
                  color="success"
                  onClick={() => 
                    appendJobsFields({
                    })
                  }>
                  Create New Job
                </Button>
              </Grid> */}

                {/****** Box *****/}
                <Grid item xs={12}>
                  <Controller
                    name="reviewed_by"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        multiple
                        {...field}
                        options={reviewByOptions}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onKeyUp={(e: any) =>
                          handleInputReviewBy(e.target.value)
                        }
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                        }}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Review By"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="total_amount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        disabled={true}
                        fullWidth
                        type="text"
                        id="total_amount"
                        label="Total Order Amount"
                        variant="outlined"
                        {...field}
                        InputProps={{
                          inputProps: { min: 0 }, 
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name="final_amount"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type="text"
                        id="final_amount"
                        label="Final Order Amount"
                        variant="outlined"
                        helperText={errors.final_amount?.message}
                        error={errors.final_amount ? true : false}
                        {...field}
                         InputProps={{
                          inputProps: { min: 0 }, 
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                    {watch('is_custom_amount') ? null : ( // Conditionally render final_discount only if is_custom_amount is not selected
                       <Controller
                          name="final_discount"
                          control={control}
                          render={({ field }) => (
                            <TextField
                            fullWidth
                          type="number"
                            id="Discount"
                            label="Discount"
                            variant="outlined"
                            helperText={errors.final_discount?.message}
                            error={errors.final_discount ? true : false}
                            {...field}
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                          />
                        )}
                      />           
                      )}
                </Grid>
                <Grid item xs={3}>
                <Controller
                  name="is_custom_amount"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={Boolean(field.value)} // Ensures the checkbox is controlled properly
                        />
                      }
                      label="Add Custom Amount"
                    />
                  )}
                />
              </Grid>                           
              </Grid>
            </CardContent>
            <CardActions sx={{ mb: 1 , ml: 3}}>
            <LoadingButton type="submit" variant="contained" size="medium" loading={isAttributePriceChanging}>
              Save
            </LoadingButton>
              <Button
                type="button"
                variant="outlined"
                size="medium"
                onClick={() => navigate('../list')}
              >
                Cancel
              </Button>
            </CardActions>
          </Box>
        </Card>
      </LocalizationProvider>
    </>
  )
})

export default QuoteEdit
