import React, { useState } from "react";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "../../../../store";
import { toast } from "react-toastify";
import Toaster from "../../../../config/Toaster";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";
import { BaseResponse } from "../../../../interfaces/ApiResponse";
import debounce from "../../../../components/utilities/Debounce";

const validationSchema = Yup.object().shape({
  responsible_persons: Yup.array().min(1, 'Please select at least one Responsible Person'),
  description: Yup.string().required("Key learning is required"),
});

const EscalationResolveModal: React.FC<any> = observer(() => {
  const { ESCALATION, USER } = useStore();
  const {
    resolveModalVisible,
    setResolveModalVisible,
    statusValues,
    changeStatus,
    setupGrid,
    agGridParams,
  } = ESCALATION;
  const { getUserList } = USER;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
    defaultValues: {
      responsible_persons: [],
      description: "",
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [responsiblePersonsOption, setResponsiblePersonsOption] = useState<any>(
    []
  );
  const onSubmit = async (_data: any) => {
    try {
      let postData = {
        id : statusValues?.id,
        escalation_status: "Resolved",
        responsible_persons: _data.responsible_persons,
        learning: _data.description,
      };
      const resData: BaseResponse = await changeStatus(postData)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        handleClose();
      } else {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
       window.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
      toast.error(errData.message, Toaster)
    }
  };
  const handleClose = () => {
    setResolveModalVisible(false);
    reset({
      responsible_persons: [],
      description: "",
    });
    setupGrid(agGridParams);
  };

  const fetchAttendeesCall = async (newValue: string) => {
    setLoading(true);
    try {
      if (newValue.length >= 1) {
        const data: any = { user: newValue };
        const res = await getUserList(data);
        setResponsiblePersonsOption(res.data.reporting_managers);
        setLoading(false);
      } else {
        setResponsiblePersonsOption([]);
        setLoading(false);
      }
    } catch (err: any) {
      const errData: BaseResponse = err;
      toast.error(errData.message, Toaster);
      setLoading(false);
    }
  };

  const debouncedfetchAttendeesCall = debounce(fetchAttendeesCall, 1000);
  const handleInputAttendees = async (newValue: any) => {
    debouncedfetchAttendeesCall(newValue);
  };

  return (
    <>
      <div>
        <Dialog
          open={resolveModalVisible}
          onClose={handleClose}
          maxWidth={`sm`}
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Resolve Escalation</DialogTitle>
          <DialogContent>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 3 }}
              onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 12, sm: 12, md: 12 }}
              >
                <Grid item xs={12}>
                  <Controller
                    name="responsible_persons"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <Autocomplete
                        multiple
                        options={responsiblePersonsOption}
                        onKeyUp={(e: any) =>
                          handleInputAttendees(e.target.value)
                        }
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal);
                        }}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.label === value.label
                        }
                        getOptionLabel={(option: any) => option.label || ""}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            label="Primary Person Responsible"
                            variant="outlined"
                            placeholder="Search by name..."
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    <CircularProgress
                                      color="inherit"
                                      size={20}
                                    />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        id="description"
                        label="Key Learnings"
                        variant="outlined"
                        multiline
                        rows={4}
                        helperText={errors.description?.message}
                        error={errors.description ? true : false}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit">Submit</Button>
                  <Button onClick={handleClose}>Close</Button>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
});

export default EscalationResolveModal;
