import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { useNavigate, useLocation } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import AssignOrderIcon from '../../../../assets/images/assign-order.png'
import OrderInfoIcon from '../../../../assets/images/info.png'
import OrderCompletedIcon from '../../../../assets/images/order-completed.png'
import OrderCancelledIcon from '../../../../assets/images/order-cancelled.png'
import Box from '@mui/material/Box'
import AgGridWrapper from '../../../../components/AgGridWrapper/AgGridWrapper'
import RecordPerPage from '../../../../components/AgGridWrapper/RecordPerPage'
import OrderDetailIcon from '../../../../assets/images/search.png'

const OrderList = observer(() => {
  const { ORDER, AUTH } = useStore()
  const navigate = useNavigate()
  const location = useLocation()
  const { fetchAuthUser } = AUTH
  const [userRole, setUserRole] = useState<number | null>(null);
  const { setupGrid, setPageSize, perPage, setCurrentOrderStatusValue, setCurrentOrderIDValue, agGridParams } = ORDER
  useEffect(() => {
    // Extract the query parameter without a key
    const queryParam = location.search.replace('?', '') // Remove the "?" from the query string

    if (queryParam && agGridParams?.api) {
      // Apply filter to Order ID column in AgGrid
      agGridParams.api.setFilterModel({
        order_id: {
          type: 'equals',
          filter: queryParam,
        },
      })

      // Trigger the grid refresh
      agGridParams.api.onFilterChanged()
    }
  }, [location.search, agGridParams])
  
  useEffect(() => {
        const fetchUserData = async () => {
          try {
            const res = await fetchAuthUser(); // Adjust to your API call
            setUserRole(res?.data?.user?.role_id || 0);
            localStorage.setItem("roleid", res?.data?.user?.role_id)
          } catch (error) {
            console.error("Error fetching user data:", error);
          } finally {
          }
        };
        fetchUserData();
  }, []);    
  
  const actionColumnRenderer = (params: any) => {
    const userRole = localStorage.getItem("roleid");
    return (
      <>
      {params.data.order_status && params.data.order_status === 'Under Review' && userRole !== "16" && <>
          <Tooltip title="Assign Order">
            <IconButton
              onClick={() => {
                navigate('../assign-order/' + params.data.enc_order_id)
              }}
            >
              <img src={AssignOrderIcon} alt="AssignOrderIcon" className='assignicon' />
            </IconButton>
          </Tooltip>
             
      </>}
      {params.data.order_status && (params.data.order_status === 'Assigned' || params.data.order_status === 'In Progress' || params.data.order_status === 'Completed') && userRole !== "16" && <>
        <Tooltip title="Order Info">
            <IconButton
              onClick={() => {
                setCurrentOrderIDValue(params?.data?.order_id)
                setCurrentOrderStatusValue(params?.data?.order_status)
                navigate('../templates/' + params.data.enc_order_id)
              }}
            >
              { params.data.order_status === "Completed" ? <img src={OrderCompletedIcon} alt="OrderCompletedIcon"/> : <img src={OrderInfoIcon} alt="OrderInfoIcon" className='' /> }
            </IconButton>
          </Tooltip>
        </>}
        {(params.data.order_status && (params.data.order_status === 'Cancelled')) && <>
            <Tooltip title={params.data.order_status}>
              <IconButton disabled={true}>
                <img src={OrderCancelledIcon} alt="OrderCancelledIcon" />
              </IconButton>
            </Tooltip>
        </>}
         {/* New Order Info Button */}
         <Tooltip title="View Order Info">
            <IconButton
              onClick={() => {
                setCurrentOrderIDValue(params?.data?.order_id)
                setCurrentOrderStatusValue(params?.data?.order_status)
                navigate('../order-details/' + params.data.enc_order_id)
              }}
            >
              <img src={OrderDetailIcon} alt='OrderDetailIcon' className='list-icon'/>
              </IconButton>
          </Tooltip>
      </>
    )
  }
  const dropdownOptions = [
    { id: 2, label: 'Email PMS Backend' },
    { id: 6, label: 'Email Uplers' },
    { id: 8, label: 'Email Mavlers' },
  ];
  const [columnDefs, ] = useState<any>([
    {
      headerName: 'Order ID',
      field: 'order_id',
    },
    {
      headerName: 'Client Email',
      field: 'client_email',
    },
    {
      headerName: 'Quote ID',
      field: 'quote_id',
      cellRenderer: (params:any) => {
        return params.value === "0" ? '' : params.value;
      },
    },
    {
      headerName: 'TL',
      field: 'assign_tl_name',
      sortable: false,
    },
    {
      headerName: 'Order From',
      field: 'order_come_from',
      filter: 'agSetColumnFilter', // Dropdown filter
      filterParams: {
        values: [2, 6, 8], // Only include the numeric IDs here
        valueFormatter: (params: any) => {
          const mapping: { [key: number]: string } = {
            2: 'Email PMS Backend',
            6: 'Email Uplers',
            8: 'Email Mavlers',
          };
          return mapping[params.value] || params.value;
        },
      },
      cellRenderer: (params: any) => {
        const mapping: { [key: number]: string } = {
          2: 'Email PMS Backend',
          6: 'Email Uplers',
          8: 'Email Mavlers',
        };
        return mapping[params.value] || params.value;
      },
    },    
    {
      headerName: 'Final Amount',
      field: 'final_amount',
    },
    {
      headerName: 'Currency',
      field: 'currency',
    },
    {
      headerName: 'Engagement Type',
      field: 'engagement_type',
    },
    {
      headerName: 'Source',
      field: 'source',
    },
    {
      headerName: 'Invoice Pattern',
      field: 'invoice_pattern',
    },
    {
      headerName: 'AM',
      field: 'assign_am_name',
      sortable: false,
    },
    {
      headerName: 'GEO',
      field: 'client_geo_name',
    },
    { 
      headerName: 'Created At',
      field: 'created_at',
      filter: 'agDateColumnFilter', 
      // extra filters params
      floatingFilterComponentParams: {
        suppressFilterButton: false, 
      },
      pinned: 'right'
    },
    {
      headerName: 'Status',
      field: 'order_status',
      pinned: 'right'
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable: false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      width: 120,
      minWidth: 80,
      pinned: 'right'
    },
  ])

  return (
    <>
      <h1>Orders</h1>
      <AgGridWrapper
        type="serverSide"
        columnDefs={columnDefs}
        onGridReady={setupGrid}
      />
      <Box className="record-per-page-filter" sx={{mt: '10px', ml: '-10px'}}>
        <RecordPerPage
          style={{ width: '150px' }}
          defaultValue={`${perPage}`}
          onChange={(e: any) => setPageSize(e.target.value)}
        />
      </Box>
    </>
  )
})

export default OrderList
