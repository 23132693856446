import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Autocomplete,
} from '@mui/material'
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material'
import { Controller } from 'react-hook-form'
import * as uuid from 'uuid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import useStore from '../../../../../../store'
import { DEFAULT_CURRENCY, EMAIL_SERVICES, dateTimeFormat } from '../../../../../../config/Constant'

const OrderJobPartial = observer(
  ({ id, register, errors, setValue, control, trigger, removeJobsFields, watch, currency }: any) => {

    const { QUOTE } = useStore()
    const { getQuoteFormFieldData, fetchAttriutePrices } = QUOTE
    const uuidValue = () => uuid.v4()
    const [, setApiCalled] = useState<any>(null)
    const [timeslotMenuItems, setTimeslotMenuItems] = useState<any[]>([
      { id: '', label: 'Select' },
    ])
    const [addOnMenuItems, setAddOnMenuItems] = useState<any[]>([
      { id: '', label: 'Select' },
    ])
    const [frameworkMenuItems, setFrameworkMenuItems] = useState<any[]>([
      { id: '', label: 'Select' },
    ])
    const [layoutMenuItems, setLayoutMenuItems] = useState<any[]>([
      { id: '', label: 'Select' },
    ])
    const [testReportMenuItems, setTestReportMenuItems] = useState<any[]>([
      { id: '', label: 'Select' },
    ])
    const [integrationMenuItems, setIntegrationMenuItems] = useState<any[]>([])
    const [integrationSetupMenuItems, setIntegrationSetupMenuItems] = useState<any[]>([])
    const fieldDataRes = useRef<any>({})
    const [dropBoxUrls, setdropBoxUrls] = useState<string>('')
    const jobs = watch("jobs"); // Get the `jobs` object or array

    const handleIntegrationChange = async (val: any) => {
      const selectedIntegrationOptions = val || [];
      setIntegrationSetupMenuItems(selectedIntegrationOptions)

      // Get the current integration setup value
      let setupValue = watch(`jobs.${id}.integration_setups`) || [];

      if(setupValue.length > 0) {
        setupValue = setupValue?.filter((setup: any) =>
          selectedIntegrationOptions.some((integration: any) => setup.id === integration.id)
        );
      }

      setValue(`jobs.${id}.integration_setups`, setupValue);
      trigger(`jobs.${id}.integration_setups`)
      // Check if integration ID 83 is included
      const hasIntegration83 = selectedIntegrationOptions.some((integration: any) => integration.id === 83);
        
      // If integration ID 83 is not selected, clear other_integration_name
      if (!hasIntegration83) {
          setValue(`jobs.${id}.other_integration_name`, ''); // Set to blank
          trigger(`jobs.${id}.other_integration_name`); // Trigger validation
      }
    }

    const handleServiceChange = async (val: any) => {
      await setTimeslotMenuItems((prev) =>
        fieldDataRes.current.timeslots?.filter((e: any) => e.service === val),
      )
    }

    // call when any field that calls api for design/dev price
    const handleAttributeChange = async (val: any) => {
      try{
      // used this to re-render component
      setApiCalled(uuidValue())

      callAttributePriceApi({
        currency: currency || DEFAULT_CURRENCY,
        service : watch(`jobs.${id}.service`),
        timeslot_id : watch(`jobs.${id}.timeslot_id`),
        layout_id : watch(`jobs.${id}.layout_id`),
        addons : watch(`jobs.${id}.addons`),
        unique_templates : watch(`jobs.${id}.unique_templates`),
        integrations : watch(`jobs.${id}.integrations`),
        other_integration_name : watch(`jobs.${id}.other_integration_name`),
        integration_setups : watch(`jobs.${id}.integration_setups`),
        adaptation_type : watch(`jobs.${id}.adaptation_type`),
        adaptation_templates : watch(`jobs.${id}.adaptation_templates`),
        test_report_id : watch(`jobs.${id}.test_report_id`),
        frameworks : watch(`jobs.${id}.frameworks`),
      });
     }catch(err: any){}
    }

    const setFormFields = async () => {
      try {
        const fieldsData: any = await getQuoteFormFieldData()
        setTimeslotMenuItems(fieldsData?.listItems?.timeslots ?? [])
        setAddOnMenuItems(fieldsData?.listItems?.addons ?? [])
        setLayoutMenuItems(fieldsData?.listItems?.layouts ?? [])
        setTestReportMenuItems(fieldsData?.listItems?.test_reports ?? [])
        setFrameworkMenuItems(fieldsData?.listItems?.frameworks ?? [])
        setIntegrationMenuItems(fieldsData?.listItems?.integrations ?? [])
        // when reder first time
        setIntegrationSetupMenuItems(watch(`jobs.${id}.integrations`) || [])
        fieldDataRes.current = fieldsData?.listItems
      } catch (err) {
        console.error(err)
      }
    }

    const callAttributePriceApi = async (data: any) => {
      try {
        const attributePricesData : any = await fetchAttriutePrices(data)
        if (attributePricesData.error === false) {
          // set design and coding amount of the current job
          setValue(`jobs.${id}.design_amount`, attributePricesData?.data?.design_amount)
          setValue(`jobs.${id}.coding_amount`, attributePricesData?.data?.coding_amount)
        }
      } catch (err: any) {
        console.error('API Error:', err);
      }
    };

    useEffect(() => {
      setFormFields()
    }, [])

    // if currency is changed then call the api
    useEffect(()=> {
      // because for the first reder we already considered it's USD only
      if(currency !== DEFAULT_CURRENCY){
        handleAttributeChange(currency)
      }
    }, [currency])

    //getting dropbox urls
    const dropbox_url = watch(`jobs.${id}.dropbox_file_urls`) || '';
    const urls = dropbox_url.split(','); // Convert the string into an array
    console.log(urls, "urlsurls")
    // getting file_urls
    const file_urls = watch(`jobs.${id}.file_url`) || '';
    //conditions logic to show other_integration_name
    const shouldShowGrid = integrationSetupMenuItems.some(integrationSetupMenuItems => integrationSetupMenuItems.id === 83);
    return (
      <Box sx={{ mb: '10px' }}>
        <Accordion defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id={`panel1a-header-${id}`}
            sx={{
              margin: '0px 0px',
              bgcolor: '#b3b1b1',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Job {id + 1}  ({jobs?.[id]?.order_job_id || ""}) ({jobs?.[id]?.service || ""})
            </Typography>
            {/* <Typography sx={{ ml: 'auto' }}>
              <IconButton
                sx={{ visibility: id === 0 ? 'hidden' : '' }}
                size="small"
                onClick={() => removeJobsFields(id)}
              >
                <DeleteForeverRoundedIcon color="error" />
              </IconButton>
            </Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ px: 10, py: 2 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, md: 12 }}
              >
                 <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.name`}
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <TextField
                        disabled={true}
                        fullWidth
                        id="name"
                        label="Name"
                        variant="outlined"
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.job_type`}
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <TextField
                        disabled={true}
                        {...field}
                        id={`jobs.${id}.job_type`}
                        select
                        label="Job Type"
                        placeholder="Job Type"
                        fullWidth
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                      >
                        <MenuItem key={uuidValue()} value={''} disabled>
                          Select Job Type
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'Templates'}>
                          Templates
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'Modules'}>
                          Modules
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                 <Controller
                  name={`jobs.${id}.service`}
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      disabled={true}
                      {...field}
                      id={`jobs.${id}.service`}
                      select
                      label="Services"
                      placeholder="Services"
                      fullWidth
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      onChange={(e: any) => {
                        field.onChange(e)
                        handleServiceChange(e.target.value)
                        handleAttributeChange(e.target.value)
                      }}
                    >
                     <MenuItem key={uuidValue()} value={''} disabled>
                          Select Service
                     </MenuItem>
                      {EMAIL_SERVICES &&
                        EMAIL_SERVICES.map((service: any) => (
                          <MenuItem key={uuidValue()} value={service.label}>
                            {service.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.timeslot_id`}
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <TextField
                        {...field}
                        id={`jobs.${id}.timeslot_id`}
                        select
                        label="Time Slot"
                        placeholder="Time Slot"
                        fullWidth
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        onChange={(e: any) => {
                          field.onChange(e)
                          handleAttributeChange(e.target.value)
                        }}
                      >
                        <MenuItem key={uuidValue()} value={''} disabled>
                          Select Time Slot
                        </MenuItem>
                        {timeslotMenuItems &&
                          timeslotMenuItems.map((e: any) => (
                            <MenuItem key={uuidValue()} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.layout_id`}
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <TextField
                        {...field}
                        id="outlined-role"
                        select
                        label="Layout"
                        fullWidth
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        onChange={(e: any) => {
                          field.onChange(e)
                          handleAttributeChange(e.target.value)
                        }}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          },
                        }}
                      >
                        <MenuItem key={uuidValue()} value={''} disabled>
                          Select Layout
                        </MenuItem>
                        {layoutMenuItems &&
                          layoutMenuItems.map((e: any) => (
                            <MenuItem key={uuidValue()} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.unique_templates`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <TextField
                        {...field}
                        id={`jobs.${id}.unique_templates`}
                        type="text"
                        label="Unique Templates"
                        placeholder="Unique Templates"
                        fullWidth
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        onKeyUp={(e: any) => {
                          handleAttributeChange(e.target.value)
                        }}
                        InputProps={{
                          inputProps: { min: 0 }, 
                        }}
                      ></TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.adaptation_type`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <TextField
                        {...field}
                        id={`jobs.${id}.adaptation_type`}
                        select
                        onChange={(e: any) => {
                          field.onChange(e)
                          handleAttributeChange(e.target.value)
                          if(e?.target?.value === ""){
                            setValue(`jobs.${id}.adaptation_templates`, 0)
                          }
                          trigger(`jobs.${id}.adaptation_type`)
                          trigger(`jobs.${id}.adaptation_templates`)
                        }}
                        label="Adaptation Type"
                        placeholder="Adaptation Type"
                        fullWidth
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                      >
                        <MenuItem key={uuidValue()} value={''}>
                          Select Adaptation Type
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'30'}>
                          30% off
                        </MenuItem>
                        <MenuItem key={uuidValue()} value={'50'}>
                          50% off
                        </MenuItem>
                      </TextField>
                    )}
                  />
                </Grid>
                {watch(`jobs.${id}.adaptation_type`) !== "" && (
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.adaptation_templates`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <TextField
                        {...field}
                        id={`jobs.${id}.adaptation_templates`}
                        type="text"
                        label="Adaptation Quantity"
                        placeholder="Adaptation Quantity"
                        fullWidth
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        onKeyUp={(e: any) => {
                          handleAttributeChange(e.target.value)
                          trigger(`jobs.${id}.adaptation_templates`)
                          trigger(`jobs.${id}.unique_templates`);
                        }}
                        InputProps={{
                          inputProps: { min: 0 }, 
                        }} 
                      ></TextField>
                    )}
                  />
                </Grid>
                )}
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.test_report_id`}
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <TextField
                        {...field}
                        id="outlined-role"
                        select
                        label="Test Report"
                        fullWidth
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        onChange={(e: any) => {
                          field.onChange(e)
                          handleAttributeChange(e.target.value)
                        }}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                              },
                            },
                          },
                        }}t
                      >
                        <MenuItem key={uuidValue()} value={''} disabled>
                          Select Test Report
                        </MenuItem>
                        {testReportMenuItems &&
                          testReportMenuItems.map((e: any) => (
                            <MenuItem key={uuidValue()} value={e.id}>
                              {e.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.integrations`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <Autocomplete
                        multiple
                        {...field}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        options={integrationMenuItems}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          handleIntegrationChange(newVal)
                          handleAttributeChange(e.target.value)
                          return
                        }}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Integrations"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                 {shouldShowGrid && (
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.other_integration_name`}
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <TextField
                        fullWidth
                        id="other_integration_name"
                        label="Other Integration Name"
                        variant="outlined"
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                )}
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.integration_setups`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <Autocomplete
                        multiple
                        {...field}
                        options={integrationSetupMenuItems}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        getOptionLabel={(option: any) => option.label || ''}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          handleAttributeChange(e.target.value)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Integrations Setup"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.addons`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <Autocomplete
                        multiple
                        {...field}
                        options={addOnMenuItems}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          handleAttributeChange(e.target.value)
                          return
                        }}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Addons"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.frameworks`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <Autocomplete
                        multiple
                        {...field}
                        options={frameworkMenuItems}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          handleAttributeChange(e.target.value)
                          return
                        }}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Frameworks"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name={`jobs.${id}.est_delivery_datetime`}
                      control={control}
                      render={({ field, fieldState }: any) => (
                        <DateTimePicker
                          disabled={true}
                          label="Est. Delivery Date & Time(GMT)"
                          {...field}
                          inputFormat={dateTimeFormat}
                          disablePast={true}
                          onChange={(newValue: any) => {
                            field.onChange(newValue)
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              id={`jobs.${id}.est_delivery_datetime`}
                              fullWidth
                              {...params}
                              error={fieldState.invalid}
                              helperText={fieldState?.error?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.design_amount`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <TextField
                      disabled={true}
                        {...field}
                        fullWidth
                        type="number"
                        id={`jobs.${id}.design_amount`}
                        label="Design Amount"
                        variant="outlined"
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        InputProps={{
                          inputProps: { min: 0 }, 
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    name={`jobs.${id}.coding_amount`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <TextField
                      disabled={true}
                        {...field}
                        fullWidth
                        type="number"
                        id={`jobs.${id}.coding_amount`}
                        label="Development Amount"
                        variant="outlined"
                        error={fieldState.invalid}
                        helperText={fieldState?.error?.message}
                        InputProps={{
                          inputProps: { min: 0 }, 
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                      name={`jobs.${id}.notes`}
                      control={control}
                      defaultValue={''}
                      render={({ field, fieldState }: any) => (
                        <TextField
                          {...field}
                          fullWidth
                          id={`jobs.${id}.notes`}
                          label="Notes"
                          variant="outlined"
                          multiline
                          rows={4}
                          error={fieldState.invalid}
                          helperText={
                            fieldState?.error?.message ||
                            fieldState?.error?.label?.message
                          }
                        />
                      )}
                    />
                </Grid>
                {urls.filter((url: string) => url.trim() !== "").length > 0 && (
                <Grid item xs={12}>
                <Typography variant="h6">Dropbox File Urls:</Typography>
                {urls
              .filter((url: string) => url.trim() !== "") // Exclude empty strings
              .map((url: string, index: number) => {
                const fileName = url.substring(url.lastIndexOf("/") + 1); // Extract file name
                const folderPath = url.substring(0, url.lastIndexOf("/") + 1); // Extract folder path

                // Encode folder path but leave file name spaces
                const encodedUrl = `https://www.dropbox.com/home${encodeURIComponent(folderPath.trim())}${fileName}`;

                return (
                  <div key={index}>
                    <a 
                      href={encodedUrl} 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      {fileName}
                    </a>
                  </div>
                );
              })}


              </Grid>
                )}
                {file_urls && (
                <Grid item xs={12}>
                <Typography variant="h6">Other File Urls:</Typography>
                  <div>
                    <a 
                      href={file_urls.trim()}
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                     {file_urls.trim()}
                    </a>
                  </div>
              </Grid>
                )}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  },
)

export default OrderJobPartial
