import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  Box,
  CardContent,
  Typography,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
  CardActions,
  Button,
  CircularProgress,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import * as uuid from 'uuid'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import AlertComponent, { AlertData } from '../../../../components/utilities/AlertComponent'
import { ESCALATION_ZONE } from '../../../../config/Constant'

const ZoneCreate = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { ZONE } = useStore()
  const { createZone, fetchClientEmail } = ZONE
  const [loading, setLoading] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)

  const validationSchema = Yup.object().shape({
    order_id: Yup.string().required('Order ID is required').matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
    client_email: Yup.object({
        label: Yup.string()
          .required('No client is associated with this order id')
          .email('Invalid email address'),
        id: Yup.string().nullable(),
    }).required('No client is associated with this order id'),
    zone: Yup.string().required('zone is required'),
    remarks: Yup.string().required('Remarks is required'),
  })

  const {
    handleSubmit,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
        order_id: "",
        client_email: { label: '', id: null },
        zone: "",
        remarks: "",
    },
  })

  const handleOrderIdChange = async (e: any) => {
      try {
        const value = e.target.value
        if(value === null || value === undefined || value === ""){
          return;
        }
        setLoading(true)
        const data: any = value
        const res = await fetchClientEmail(data)
        if(res.error === false){
            setValue(`client_email`, res?.data?.order_data?.client_email)
            trigger(`client_email`)
        }else{
            setValue(`client_email`, { id: null, label : '' })
            trigger(`client_email`)
        }
        setLoading(false)
    } catch (err) {
      setValue(`client_email`, { id: null, label : '' })
      trigger(`client_email`)
      setLoading(false)
      console.error(err)
    }
  }

  const onSubmit = async (_data: any) => {
    try {
      const payload = {
        order_id: _data?.order_id,
        client_email_id : _data?.client_email?.id,
        zone : _data?.zone,
        remarks : _data?.remarks
      }
      const resData: any = await createZone(payload)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        navigate('../list')
      } else {
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: resData.message, data: resData.data});
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err;
      window.scrollTo({top: 0, behavior: 'smooth' });
      setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      toast.error(err.message, Toaster)
    }
  }

  console.error('errors', errors)

  return (
    <>
    <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mt: 3, mb: 3, px: 2 }}
            >
              Add Zone
            </Typography>
            <Grid
              sx={{ px: 2 }}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 3, sm: 6, md: 12 }}
            >
              <Grid item xs={4}>
                <Controller
                  name="order_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="order_id"
                      label="Order ID"
                      variant="outlined"
                      helperText={errors.order_id?.message}
                      error={errors.order_id ? true : false}
                      {...field}
                      onKeyUp={(e: any) => {
                        handleOrderIdChange(e)
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="client_email"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={[]}
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      readOnly={true}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Email"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="zone"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-select-zone"
                      select
                      label="Zone"
                      placeholder="Zone"
                      fullWidth
                      helperText={errors.zone?.message}
                      error={errors.zone ? true : false}
                      onChange={(e: any) => {
                        field.onChange(e)
                      }}
                    >
                      <MenuItem key={uuidValue()} value={''} disabled>
                        Select Zone
                      </MenuItem>
                        {ESCALATION_ZONE &&
                          ESCALATION_ZONE.map((e: any) => (
                            <MenuItem key={uuidValue()} value={e.label}>
                              {e.label}
                            </MenuItem>
                          ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                    name={`remarks`}
                    control={control}
                    defaultValue={''}
                    render={({ field, fieldState }: any) => (
                    <TextField
                        {...field}
                        fullWidth
                        id={`remarks`}
                        label="Remarks"
                        variant="outlined"
                        multiline
                        rows={4}
                        error={fieldState.invalid}
                        helperText={
                        fieldState?.error?.message ||
                        fieldState?.error?.label?.message
                        }
                    />
                    )}
                />
             </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ mb: 1 , ml: 1}}>
            <LoadingButton type="submit" variant="contained" size="medium" loading={loading}>
              Save
            </LoadingButton>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={() => navigate('../list')}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default ZoneCreate
