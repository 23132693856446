import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  Box,
  Grid,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import * as uuid from 'uuid'
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import { CLIENT_TYPES } from '../../../../config/Constant'
import AlertComponent, { AlertData } from '../../../../components/utilities/AlertComponent'

const ClientCreate = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { CLIENT } = useStore()
  const { getClientFormFieldData, createClient } = CLIENT
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  const [companyDomain, setCompanyDomain] = useState('');
  const [commonDomain, setCommonDomain] = useState('');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        domain_name: Yup.string()
        .test(
          'required-domain-wise',
          'Domain name is required for Domain Wise registration',
          function (value) {
            const { registration_type } = this.parent; // Access the parent context
            if (registration_type === '0' && !value) {
              return false; // Fail the validation if registration type is Domain Wise and domain_name is empty
            }
            return true; // Pass validation otherwise
          }
        ),
        client_type: Yup.string().required('Client Type is required'),
        geo_name: Yup.string().required('Geo Name is required'),
        // phone: Yup.string().matches(/^[0-9]{10}$/, 'Invalid phone number').nullable(),
        registration_type: Yup.string().required('Registration type is required'),
        client_emails: Yup.array()
          .of(
            Yup.object().shape({
              email: Yup.string()
                .required('Email is required')
                .email('Invalid email address')
                .test('domain-match', 'Email must belong to the company domain', (email) => {
                  if (companyDomain && registrationTypeValue === '0') {
                    // const domainRegex = new RegExp(`^[a-zA-Z0-9._%+-]+@${companyDomain}$`);
                    const trimmedDomain = companyDomain.trim();
                    const domainRegex = new RegExp(`^[a-zA-Z0-9._%+-]+@${trimmedDomain.replace(/\./g, '\\.')}$`);
                    return domainRegex.test(email);
                  }
                  return true;
                })
                .test('restricted-email',  function () {

                  return `Common domains allowed: ${commonDomain}`;
                },
                function (email) {
                  if (registrationTypeValue === '1') {
                    const allowedDomains = commonDomain;
                    const emailDomain = email.split('@')[1];
                    if (!allowedDomains.includes(emailDomain)) {
                      return false;
                    }
                  }
                  return true;
                }),
            })
          )
          .required('At least one email is required')
          .min(1, 'At least one email is required'),
        client_billing_emails: Yup.array()
        .of(
          Yup.object().shape({
            email: Yup.string()
              .required('Email is required')
              .email('Invalid email address')
              .test('domain-match', 'Email must belong to the company domain', (email) => {
                if (companyDomain && registrationTypeValue === '0') {
                  const trimmedDomain = companyDomain.trim();
                  const domainRegex = new RegExp(`^[a-zA-Z0-9._%+-]+@${trimmedDomain.replace(/\./g, '\\.')}$`);
                  // const domainRegex = new RegExp(`^[a-zA-Z0-9._%+-]+@${companyDomain}$`);
                  return domainRegex.test(email);
                }
                return true;
              })
              .test('restricted-email',  function () {

                return `Common domains allowed: ${commonDomain}`;
              },
              function (email) {
                if (registrationTypeValue === '1') {
                  const allowedDomains = commonDomain;
                  const emailDomain = email.split('@')[1];
                  if (!allowedDomains.includes(emailDomain)) {
                    return false;
                  }
                }
                return true;
              }),
          })
        )
        .required('At least one email is required')
        .min(1, 'At least one email is required'),
      }),
    [companyDomain, commonDomain] // Dependency array to update when `companyDomain` or `registrationTypeValue` changes
  );
  
  
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors, // Include clearErrors here
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      // client_name: '',
      domain_name: '',
      client_type: '',
      geo_name: '',
      // phone: '',
      registration_type: '0', // Set the initial value in useForm's defaultValues
      client_emails: [{ email : "" }],
      client_billing_emails: [{ email : "" }],
    },
  })

  const {
    fields: clientEmailsFields,
    append: appendClientEmails,
    remove: removeClientEmails,
  } = useFieldArray<any>({
    control,
    name: 'client_emails',
  })

  const {
    fields: billingEmailsFields,
    append: appendBillingEmails,
    remove: removeBillingEmails,
  } = useFieldArray<any>({
    control,
    name: 'client_billing_emails',
  })

  const [geoList, setGeoList] = useState<any>([])

  const setFormFields = async () => {
    try {
      const fieldsData: any = await getClientFormFieldData()
      await setGeoList(fieldsData?.data?.data?.geos)
      await setCommonDomain(fieldsData?.data?.data?.common_domains.join(", "))
    } catch (err: any) {
      console.error(err)
      toast.error(err.message, Toaster)
    }
  }

  const registrationTypeValue = useWatch({
    control,
    name: 'registration_type', // Watch this specific field
  });
  const companyName =  useWatch({
    control,
    name: 'domain_name', // Watch this specific field
  });
  useEffect(() => {
    if(companyName)
    {
      // const domain = companyName?.split('.').slice(-2).join('.'); // Get last two parts of the domain, e.g., "upler.in"
      setCompanyDomain(companyName);
      clearErrors('client_emails'); // Clear errors when the domain changes
    }
else{
  setCompanyDomain("");
  setValue(`registration_type`, "1");


}
  }, [companyName, clearErrors]);

  const handleEmailChange = (index: number, value: string) => {
    // Update the email value
    setValue(`client_emails.${index}.email`, value);

    // Check if the email matches the company domain
    if (companyDomain && value) {
      const domainRegex = new RegExp(`^[a-zA-Z0-9._%+-]+@${companyDomain}$`);
      if (domainRegex.test(value)) {
        // Clear the error for this email field if valid
        clearErrors(`client_emails.${index}.email`);
      }
    } else {
      // Clear the email error if no domain is set yet
      clearErrors(`client_emails.${index}.email`);
    }
  };

  const handleBillingEmailChange = (index: number, value: string) => {
    // Update the email value
    setValue(`client_billing_emails.${index}.email`, value);
  
    // Check if the email matches the company domain
    if (companyDomain && value) {
      const domainRegex = new RegExp(`^[a-zA-Z0-9._%+-]+@${companyDomain}$`);
      if (domainRegex.test(value)) {
        // Clear the error for this email field if valid
        clearErrors(`client_billing_emails.${index}.email`);
      }
    } else {
      // Clear the email error if no domain is set yet
      clearErrors(`client_billing_emails.${index}.email`);
    }
  };
  
  useEffect(() => {
    if (registrationTypeValue === '1') {
      // Clear the email values in client_emails fields
      setValue('client_emails', [{ email: "" }]); // Reset to one empty email field
      setValue('client_billing_emails', [{ email: "" }]); // Reset to one empty email field
      setValue('domain_name', ""); 

    } else {
      // You can optionally reset to some default values when switching back
      // setValue('client_emails', [{ email: "" }]);
    }
  }, [registrationTypeValue, setValue]);
  
  useEffect(() => {
    setFormFields()
  }, [])
  const onSubmit = async (_data: any) => {
    try {
      _data.client_emails = _data.client_emails.map((item:any) => item.email);
      _data.client_billing_emails = _data.client_billing_emails.map((item:any) => item.email);
      if(_data.registration_type == "1")
        {
          _data.client_name = _data.client_emails[0]
          if (Array.isArray(_data.client_emails) && _data.client_emails[0]?.includes('@')) {
            const domainName = _data.client_emails[0].split('@')[1];
            _data.domain_name = domainName || ""; // Fallback to an empty string if domain is undefined
          }
        }
      if(_data.registration_type == "0")
        {
          _data.client_name = _data.client_emails[0]  
        }
      const resData: any = await createClient(_data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        navigate('../list')
      } else {
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: resData.message, data: resData.data});
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      toast.error(errData.message, Toaster)
      if (errData?.data?.exists_email) {
        toast.error(errData?.data?.exists_email, Toaster)
      }
      window.scrollTo({top: 0, behavior: 'smooth' });
      setAlertMessage({severity:'error', message: errData.message, data: errData.data});

    }
  }

  return (
    <>
    <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Add Client
            </Typography>
            <br />
            <Grid
              container
              spacing={{ xs: 2, md: 1 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <br />
               <Typography variant="body2" color="textSecondary" sx={{ mb: 1, ml: 2 }}>
                  For <strong>Domain Wise</strong>: Use this option for company-specific domains like uplers.com, mavlers.com, etc.
                  <br />
                  For <strong>Email Address Wise</strong>: Use this option for common email domains like {commonDomain}.
                </Typography>
              <Grid item xs={6}>
                {/* <Controller
                  name="client_name"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      fullWidth
                      id="client_name"
                      label="Client Name"
                      variant="outlined"
                      helperText={errors?.client_name?.message}
                      error={errors.client_name ? true : false}
                      {...field}
                    />
                  )}
                /> */}

                <Controller
                  name="registration_type"
                  control={control}
                  defaultValue="0" // Set default value for this field
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      id="registration_type"
                      select
                      label="Registration Type"
                      fullWidth
                      helperText={errors.registration_type?.message}
                      error={!!errors.registration_type}
                    >
                      <MenuItem key="placeholder" disabled value={''}>
                        Select Registration Type
                      </MenuItem>
                      <MenuItem key="domain-wise" value="0">
                        Domain Wise
                      </MenuItem>
                      <MenuItem key="email-address-wise" value="1">
                        Email Address Wise
                      </MenuItem>
                    </TextField>
                  )}
                />
              </Grid>

              {/* Conditionally render domain_name */}
            {registrationTypeValue !== '1' && (
              <Grid item xs={6} >
                <Controller
                  name="domain_name"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      fullWidth
                      id="domain_name"
                      label="Domain Name"
                      variant="outlined"
                      helperText={errors.domain_name?.message}
                      error={errors.domain_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
            )}  
              <Grid item xs={6}>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      fullWidth
                      id="phone"
                      label="Phone"
                      variant="outlined"
                      helperText={errors.phone?.message}
                      error={errors.phone ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="client_type"
                  control={control}
                  defaultValue={'1'} // Set default value for this field
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      id="client_type"
                      select
                      label="Client Type"
                      fullWidth
                      helperText={errors.client_type?.message}
                      error={errors.client_type ? true : false}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select Client Type
                      </MenuItem>
                      {CLIENT_TYPES &&
                        CLIENT_TYPES.map((type: any) => (
                          <MenuItem key={uuidValue()} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="geo_name"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      id="geo_name"
                      select
                      label="GEO"
                      fullWidth
                      helperText={errors.geo_name?.message}
                      error={errors.geo_name ? true : false}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select GEO
                      </MenuItem>
                      {geoList &&
                        geoList.map((geo: any) => (
                          <MenuItem key={uuidValue()} value={geo.label}>
                            {geo.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              
              <Grid container item xs={12}>
                {clientEmailsFields.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <Grid
                        item
                        xs={12}
                        sx={{ mr: '80px', mb: '8px', mt: '8px' }}
                      >
                        <Controller
                          name={`client_emails.${index}.email`}
                          control={control}
                          render={({ field }: any) => (
                            <TextField
                              fullWidth
                              id={`client_emails.${index}.email`}
                              label={`Client Email ${(index + 1) === 1 ? "" : index + 1 } `}
                              variant="outlined"
                              helperText={errors?.client_emails?.[index]?.email?.message || ''}
                              error={!!errors?.client_emails?.[index]?.email}
                              {...field}
                              onChange={(e) => handleEmailChange(index, e.target.value)} // Trigger email change logic
                            />
                          )}
                        />
                        <Tooltip
                          title="Remove"
                          sx={{ visibility: index === 0 ? 'hidden' : '' }}
                        >
                          <IconButton onClick={() => removeClientEmails(index)}>
                            <DeleteForeverRoundedIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </div>
                  )
                })}
              </Grid>
              {registrationTypeValue !== '1' && (
              <Grid container item justifyContent="center" alignItems="center">
                <Button
                  size="medium"
                  startIcon={<AddCircleIcon />}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    appendClientEmails({ email : "" })
                  }}
                >
                  Add Client Emails
                </Button>
              </Grid>
              )}
              <Grid container item xs={12}>
                {billingEmailsFields.map((item, index) => {
                  return (
                    <div key={item.id}>
                      <Grid
                        item
                        xs={12}
                        sx={{ mr: '80px', mb: '8px', mt: '8px' }}
                      >
                        <Controller
                          name={`client_billing_emails.${index}.email`}
                          control={control}
                          render={({ field }: any) => (
                            <TextField
                              fullWidth
                              id={`client_billing_emails.${index}.email`}
                              label={`Billing Email ${(index + 1) === 1 ? "" : index + 1 } `}
                              variant="outlined"
                              helperText={errors?.client_billing_emails?.[index]?.email?.message || ''}
                              error={!!errors?.client_billing_emails?.[index]?.email}
                              {...field}
                              onChange={(e) => handleBillingEmailChange(index, e.target.value)} // Trigger email change logic

                            />
                          )}
                        />
                        <Tooltip
                          title="Remove"
                          sx={{ visibility: index === 0 ? 'hidden' : '' }}
                        >
                          <IconButton
                            onClick={() => removeBillingEmails(index)}
                          >
                            <DeleteForeverRoundedIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </div>
                  )
                })}
              </Grid>
              {registrationTypeValue !== '1' && (
              <Grid container item justifyContent="center" alignItems="center">
                <Button
                  size="medium"
                  startIcon={<AddCircleIcon />}
                  variant="contained"
                  color="success"
                  onClick={() => {
                    appendBillingEmails({ email : "" })
                  }}
                >
                  Add Billing Emails
                </Button>
              </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={() => navigate('../list')}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default ClientCreate
