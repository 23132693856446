import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Box, CardContent, Typography, TextField, CardActions, Button, Grid, Autocomplete, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Toaster from '../../../../../config/Toaster';
import { BaseResponse } from '../../../../../interfaces/ApiResponse';
import useStore from '../../../../../store';
import * as Yup from "yup";
import { observer } from 'mobx-react'
import AlertComponent, { AlertData } from '../../../../../components/utilities/AlertComponent';
import debounce from '../../../../../components/utilities/Debounce'
import { DEBOUNCE_AFTER_CHARS } from '../../../../../config/Constant';

const validationSchema = Yup.object().shape({
    checklist_type: Yup.number(),
    title: Yup.string()
      .required('Title is required')
      .min(2, 'Title must be at least 2 characters long')
      .matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
    description: Yup.string().required('Description is required'),
    client: Yup.object({
      label: Yup.string().required('Client is required'),
      id: Yup.number().nullable(),
    }).required('Client is required'),
  });

const ClientChecklistEdit = observer(() => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState<boolean>(false)
    const { CHECKLIST, CLIENT } = useStore();
    const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
    const [clientMenuItems, setClientMenuItems] = useState<any>([])
    const { fetchChecklist, updateChecklist} = CHECKLIST;
    const { getClients } = CLIENT
    const {
      handleSubmit,
      setError,
      setValue,
      control,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
      mode: "onTouched",
      defaultValues: {
        checklist_type: 2,
        title: '',
        description: '',
        client: { label: '', id: null },
      },
    });
  
  
    // debounce fetch client call
    const fetchClientCall = async (newValue: string) => {
      setLoading(true)
      try {
        if (newValue.length >= DEBOUNCE_AFTER_CHARS) {
          const data: any = { name: newValue }
          const res = await getClients(data)
          setClientMenuItems(res.data.clients)
          setLoading(false)
        } else {
          setClientMenuItems([]) // Clear options if input length is less than 3
          setLoading(false)
        }
      } catch (err) {
        setLoading(false)
        console.error(err)
      }
    }
    const debouncedHandleClientInputChange = debounce(
      fetchClientCall,
      1000,
    )

    // Fetch data from the server when input value changes
    const handleInputClient = async (newValue: any) => {
      debouncedHandleClientInputChange(newValue)
    }

    const onSubmit = async (_data: any) => {
        try {
            const postData = {
                checklist_type: 2,
                title: _data?.title,
                description: _data?.description,
                client_id: _data?.client?.id,
            }
          const resData: BaseResponse = await updateChecklist(id, postData);
            if(resData.error === false){
              toast.success(resData?.message, Toaster)
              navigate("../client-checklists")
            }else{
              toast.error(resData?.message, Toaster)
              setAlertMessage({severity:'error', message: resData.message, data: resData.data});
            }
          } catch(err:any){
            console.error(err)
            const errData: BaseResponse = err;
            if(errData.data && errData.data['title']){
              setError("title", {
                type: "manual",
                message: errData.data['title'][0],
              });
            }
            toast.error(errData?.message, Toaster);
            window.scrollTo({top: 0, behavior: 'smooth' });
            setAlertMessage({severity:'error', message: errData.message, data: errData.data});
          }
    };
  
    const fetchChecklistData = async (_id: string | undefined) => {
      try {
        const resData: any = await fetchChecklist(id)
        if (resData.error === false) {
          setValue('client', resData?.data?.checklist?.client || '')
          setValue('title', resData?.data?.checklist?.title || '')
          setValue('description', resData?.data?.checklist?.description || '')
        } else {
           toast.error(resData?.message, Toaster)
           window.scrollTo({top: 0, behavior: 'smooth' });
           setAlertMessage({severity:'error', message: resData.message, data: resData.data});
        }
      } catch (err: any) {
        console.error(err)
        const errData: BaseResponse = err
        toast.error(errData.message, Toaster)
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      }
    }
  
    useEffect(() => {
      fetchChecklistData(id)
    }, [])
    
    return (
      <>
        <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
        <Card sx={{ maxWidth: "100%"}} variant='outlined'>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ mt: 2, mb: 2 }}
              >
                Edit Client Checklist
              </Typography>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12}>
                  <Controller
                    name="client"
                    control={control}
                      render={({ field, fieldState }: any) => (
                      <Autocomplete
                        {...field}
                        options={clientMenuItems ?? []}
                        onKeyUp={(e: any) =>
                          handleInputClient(e.target.value)
                        }
                        onChange={(e, newVal: any) => {
                          field.onChange(newVal)
                        }}
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.label === value.label
                        }
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Client"
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                        <TextField
                        fullWidth
                        id="title"
                        label="Title"
                        variant="outlined"
                        helperText={errors.title?.message}
                        error={errors.title ? true : false}
                        {...field}
                        />
                    )}
                    />
                </Grid>
                <Grid item xs={12}>
                <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                    <TextField
                        fullWidth
                        id="description"
                        label="Description"
                        variant="outlined"
                        multiline
                        rows={4}
                        helperText={errors.description?.message}
                        error={errors.description ? true : false}
                        {...field}
                    />
                    )}
                />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
                Save
              </Button>
              <Button type="button" variant="outlined" size="medium" onClick={ (e) => navigate(-1)}>
                Cancel
              </Button>
            </CardActions>
          </Box>
        </Card>
      </>
    );
})

export default ClientChecklistEdit