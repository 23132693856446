import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import EditIcon from '../../../../../assets/images/note.png'
import { useNavigate } from 'react-router-dom'
import AgGridWrapper from '../../../../../components/AgGridWrapper/AgGridWrapper'
import useStore from '../../../../../store'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AllPermissionList from '../../../../../config/AllPermissionList'
import { Stack } from '@mui/material'

const ClientChecklistList = observer(() => {
  const { CHECKLIST, AUTH } = useStore()
  const navigate = useNavigate()
  const { fetchList } = CHECKLIST
  const [rowData, setRowData] = useState<any>([])
  const { permissions } = AUTH

  const actionColumnRenderer = (params: any) => {
    return (
      <>
        <Tooltip
          title="Edit"
          sx={{
            display:
              permissions &&
              permissions.indexOf(AllPermissionList.EDIT_CHECKLIST) >= 0
                ? 'inline-flex'
                : 'none',
          }}
        >
          <IconButton
            onClick={() => {
              navigate('../client-checklists/edit/' + params?.data?.enc_id)
            }}
          >
             <img src={EditIcon} alt="edit" className='editicon' />
          </IconButton>
        </Tooltip>
      </>
    )
  }

  const [columnDefs] = useState<any>([
    {
      headerName: 'Client Name',
      field: 'client_name',
    },
    {
      headerName: 'Title',
      field: 'title',
    },
    {
      headerName: 'Description',
      field: 'description',
    },
    {
      headerName: 'Created By',
      field: 'created_by.label',
    },
    {
      headerName: 'Updated By',
      field: 'updated_by.label',
    },
    {
      headerName: 'Created At',
      field: 'created_at',
      pinned: "right",
    },
    {
      headerName: 'Action',
      field: 'action',
      sortable: false,
      filter: false,
      cellRenderer: actionColumnRenderer,
      pinned: "right",

    },
  ])

  useEffect(() => {
    fetchList({
      checklist_type: 2,
    })
      .then((data: any) => {
        setRowData(data)
      })
      .catch((error: any) => {
        setRowData([])
      })
  }, [fetchList])

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        spacing={2}
        sx={{ my: '20px' }}
      >
        <Box></Box>
        <Box sx={{ mb: '20px' }}>
          <Button
            onClick={() => navigate('create')}
            variant="contained"
            startIcon={<AddCircleIcon />}
            sx={{
              display:
                permissions &&
                permissions.indexOf(AllPermissionList.ADD_CHECKLIST) >= 0
                  ? 'inline-flex'
                  : 'none',
            }}
          >
            NEW CLIENT CHECKLIST
          </Button>
        </Box>
      </Stack>
      <Box sx={{}}>
        <AgGridWrapper
          type="clientSide"
          columnDefs={columnDefs}
          rowData={rowData}
        />
      </Box>
    </>
  )
})

export default ClientChecklistList
