import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Autocomplete,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  Box,
  Grid,
  MenuItem,
} from '@mui/material'
import * as uuid from 'uuid'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import dayjs from 'dayjs'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import { DEBOUNCE_AFTER_CHARS, DISCUSSION_TYPES, dateTimeFormat } from '../../../../config/Constant'
import debounce from '../../../../components/utilities/Debounce'
import AlertComponent, { AlertData } from '../../../../components/utilities/AlertComponent'

const DiscussionCreate = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { DISCUSSION, USER, CLIENT } = useStore()
  const { getDiscussionFormFieldData, createDiscussion } = DISCUSSION
  const { getUserList } = USER
  const { getClientEmails } = CLIENT

  const [discussionType, setDiscussionType] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [attendeesOption, setAttendeesOption] = useState<any>([])
  const [clientMailOptions, setClientMailOptions] = useState<any>([]);
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  
  const validationSchema = Yup.object().shape({
    discussion_type: Yup.string().required('Discussion Type is required'),
    discussion_date_time: Yup.string().required(
      'Discussion date time is required',
    ),
    meeting_link: Yup.string()
      .url('Please enter a valid meeting link'),
    subject_line: Yup.string().required('Subject line is required'),
    description: Yup.string().required('Description is required'),
    client_email: Yup.object({
      label: Yup.string()
        .required('Client email is required')
        .email('Invalid email address'),
      id: Yup.string().nullable(),
      client_id: Yup.string().nullable(),
    }).required('Client email is required'),
    order_id: Yup.string().nullable().test('special-characters', 'Special characters are not allowed', function(value: any) {
      if (value !== "" && value !== null && value !== undefined) {
        return /^[a-zA-Z0-9 ]+$/.test(value);
      }
      return true; // Return true for null values to skip validation
    }),
    attendees: Yup.array().min(1, 'Please select at least one attendee'),
  })


  const {
    handleSubmit,
    control,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      discussion_type: '',
      discussion_date_time: dayjs().toString(),
      meeting_link: '',
      description: '',
      subject_line: '',
      client_email: { label: '', id: null, client_id : null },
      order_id : "",
      attendees: [],
    },
  })

  const setFormFields = async () => {
    try {
      await getDiscussionFormFieldData()
    } catch (err: any) {
      const errData: BaseResponse = err;
      window.scrollTo({top: 0, behavior: 'smooth' });
      setAlertMessage({severity:'error', message: errData.message, data: errData.data});
      toast.error(err.message, Toaster)
    }
  }

  useEffect(() => {
    setFormFields()
  }, [])

    // debounce fetch client email call
    const fetchClientEmailsCall = async (newValue: string) => {
      setLoading(true);
      try{
        if (newValue.length >= DEBOUNCE_AFTER_CHARS) {
          const data: any = {email: newValue}
          const res = await getClientEmails(data)
          await setClientMailOptions(res.data.emails);
          await setLoading(false);
        } else {
          setClientMailOptions([]); // Clear options if input length is less than 3
          await setLoading(false);
        }
      }catch(err: any){
        await setLoading(false);
        console.error(err)
      } 
    }

  const debouncedHandleClientEmailInputChange = debounce(fetchClientEmailsCall, 1000);

  const handleInputClientEmail = async (newValue:any) => {
    debouncedHandleClientEmailInputChange(newValue)
  };
  const onSelectClientEmail = async (e: any,selectedOption: any) => {
    if(selectedOption){
    }
  };

  const fetchAttendeesCall = async (newValue: string) => {
    setLoading(true)
    try {
      if (newValue.length >= 1) {
        const data: any = { user: newValue }
        const res = await getUserList(data)
        setAttendeesOption(res.data.reporting_managers)
        setLoading(false)
      } else {
        setAttendeesOption([])
        setLoading(false)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      toast.error(errData.message, Toaster)
      setLoading(false)
    }
  }

  const debouncedfetchAttendeesCall = debounce(fetchAttendeesCall, 1000)
  const handleInputAttendees = async (newValue: any) => {
    debouncedfetchAttendeesCall(newValue)
  }

  console.error('errors', errors)
  const onSubmit = async (_data: any) => {
    try {
      _data.client_email_id = _data?.client_email?.id;
      _data.client_id = _data?.client_email?.client_id;
      _data.discussion_date_time = dayjs(_data.discussion_date_time).format(dateTimeFormat)
      _data.attendees = _data.attendees.map((item:any) => item.id).join(',');

      const resData: any = await createDiscussion(_data)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        navigate('../list')
      } else {
        window.scrollTo({top: 0, behavior: 'smooth' });
        setAlertMessage({severity:'error', message: resData.message, data: resData.data});
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
      toast.error(err.message, Toaster)
      window.scrollTo({top: 0, behavior: 'smooth' });
      setAlertMessage({severity:'error', message: errData.message, data: errData.data});
    }
  }

  return (
    <>
     <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mt: 2, mb: 2 }}
            >
              Add Discussion
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="discussion_type"
                  control={control}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      id="discussion_type"
                      select
                      label="Discussion Type"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e)
                        setDiscussionType(e.target.value)
                      }}
                      helperText={errors.discussion_type?.message}
                      error={errors.discussion_type ? true : false}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select Type
                      </MenuItem>
                      {DISCUSSION_TYPES &&
                        DISCUSSION_TYPES.map((type: any) => (
                          <MenuItem key={uuidValue()} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="discussion_date_time"
                    control={control}
                    render={({ field }) => (
                      <DateTimePicker
                        label="Discussion Date Time"
                        {...field}
                        inputFormat={dateTimeFormat}
                        disablePast={true}
                        onChange={(newValue: any) => {
                          field.onChange(newValue)
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            id="discussion_date_time"
                            fullWidth
                            {...params}
                            helperText={errors.discussion_date_time?.message}
                            error={errors.discussion_date_time ? true : false}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="client_email"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={clientMailOptions}
                      onKeyUp={(e: any) =>
                        handleInputClientEmail(e.target.value)
                      }
                      onChange={(e, newVal: any) => {
                        onSelectClientEmail(e, newVal)
                        field.onChange(newVal)
                      }}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Email"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="order_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="order_id"
                      label="Order ID"
                      variant="outlined"
                      helperText={errors.order_id?.message}
                      error={errors.order_id ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="meeting_link"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="meeting_link"
                      label="Meeting Link"
                      variant="outlined"
                      helperText={errors.meeting_link?.message}
                      error={errors.meeting_link ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="subject_line"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="subject_line"
                      label="Subject Line"
                      variant="outlined"
                      helperText={errors.subject_line?.message}
                      error={errors.subject_line ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="description"
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                      helperText={errors.description?.message}
                      error={errors.description ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="attendees"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      multiple
                      options={attendeesOption || []}
                      isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                      onKeyUp={(e: any) => handleInputAttendees(e.target.value)}
                      onChange={(e: any, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          label="Attendees"
                          variant="outlined"
                          placeholder="Search by name..."
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={() => navigate('../list')}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default DiscussionCreate
