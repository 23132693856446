import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  Box,
  Grid,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from '@mui/material'
import * as uuid from 'uuid'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import AlertComponent, { AlertData } from '../../../../components/utilities/AlertComponent'
import debounce from '../../../../components/utilities/Debounce'
import { DEBOUNCE_AFTER_CHARS, ESCALATION_IMPACT, ESCALATION_TYPE } from '../../../../config/Constant'

const validationSchema = Yup.object().shape({
  client_email: Yup.object({
    label: Yup.string()
      .required('Client email is required')
      .email('Invalid email address'),
    id: Yup.string().nullable(),
    client_id: Yup.string().nullable(),
  }).required('Client email is required'),
  order_id: Yup.string().nullable().test('special-characters', 'Special characters are not allowed', function(value: any) {
    if (value !== "" && value !== null && value !== undefined) {
      return /^[a-zA-Z0-9 ]+$/.test(value);
    }
    return true; // Return true for null values to skip validation
  }),
  subject_line: Yup.string().nullable(),
  am: Yup.object({
    label: Yup.string()
      .required('AM is required'),
    id: Yup.string().nullable(),
  }).required('AM is required'),
  pc: Yup.object({
    label: Yup.string()
      .required('PC is required'),
    id: Yup.string().nullable(),
  }).required('PC is required'),
  type: Yup.string().required('Type is required'),
  category: Yup.object({
    label: Yup.string()
      .required('Category is required'),
    id: Yup.string().nullable(),
  }).required('Category is required'),
  sub_category: Yup.object({
    label: Yup.string()
      .required('Sub-category is required'),
    id: Yup.string().nullable(),
    escalation_category_id: Yup.string().nullable(),
  }).required('Sub-category is required'),
  description: Yup.string().required('Description is required'),
  impact: Yup.string().required('Impact is required'),
})

const EscalationCreate = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { ESCALATION, CLIENT } = useStore()
  const { getCreateFormFieldData, createEscalation } = ESCALATION
  const { getClientEmails } = CLIENT
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      client_email: { label: '', id: null, client_id: null },
      order_id: "",
      subject_line: "",
      am: { label: '', id: null },
      pc: { label: '', id: null },
      type: "",
      category: { label: '', id: null },
      sub_category: { label: '', id: null, escalation_category_id: "" },
      description: "",
      impact: "",
    },
  })

  console.error(errors);
  const [loading, setLoading] = useState<boolean>(false)
  const [clientMailOptions, setClientMailOptions] = useState<any>([])
  const [amMenuItems, setAmMenuItems] = useState<any>([])
  const [pcMenuItems, setPcMenuItems] = useState<any>([])
  const [categoryMenuItems, setCategoryMenuItems] = useState<any>([])
  const [subCategoryMenuItems, setSubCategoryMenuItems] = useState<any>([])
  const fieldDataRes = useRef<any>({})
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)

  // debounce fetch client email call
  const fetchClientEmailsCall = async (newValue: string) => {
    setLoading(true)
    try {
      if (newValue.length >= DEBOUNCE_AFTER_CHARS) {
        const data: any = { email: newValue }
        const res = await getClientEmails(data)
        await setClientMailOptions(res.data.emails)
        await setLoading(false)
      } else {
        setClientMailOptions([]) // Clear options if input length is less than 3
        await setLoading(false)
      }
    } catch (err) {
      await setLoading(false)
      console.error(err)
    }
  }
  const debouncedHandleClientEmailInputChange = debounce(
    fetchClientEmailsCall,
    1000,
  )

  // Fetch data from the server when input value changes
  const handleInputClientEmail = async (newValue: any) => {
    debouncedHandleClientEmailInputChange(newValue)
  }

  const setFormFields = async () => {
    try {
      const fieldsData: any = await getCreateFormFieldData()
      fieldDataRes.current = fieldsData?.data?.data;
      setAmMenuItems(fieldsData?.data?.data?.AMs)
      setPcMenuItems(fieldsData?.data?.data?.PCs)
      setCategoryMenuItems(fieldsData?.data?.data?.escalation_categories)
      setSubCategoryMenuItems(fieldsData?.data?.data?.escalation_sub_categories)
    } catch (err: any) {
      console.error(err)
      const errData: BaseResponse = err
       window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
      setAlertMessage({
          severity:'error',
          message: errData.message,
          data: errData.data
        });
      toast.error(errData.message, Toaster)
    }
  }

  useEffect(() => {
    setFormFields()
  }, [])

  const onSubmit = async (_data: any) => {
    try {
      let postData = {
        order_id: _data?.order_id,
        subject_line: _data?.subject_line,
        am_id: _data?.am.id,
        pc_id: _data?.pc.id,
        escalation_type: _data?.type,
        escalation_category_id: _data?.category?.id,
        escalation_sub_category_id: _data?.sub_category?.id,
        client_email_id: _data?.client_email?.id,
        description: _data?.description,
        impact: _data?.impact,
      };
      const resData: BaseResponse = await createEscalation(postData)
      if (resData.error === false) {
        toast.success(resData.message, Toaster)
        navigate('../list')
      } else {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        setAlertMessage({
          severity:'error',
          message: resData.message,
          data: resData.data
        });
        toast.error(resData.message, Toaster)
      }
    } catch (err: any) {
      const errData: BaseResponse = err
       window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
      setAlertMessage({
        severity:'error',
        message: errData.message,
        data: errData.data
      });
      toast.error(errData.message, Toaster)
    }
  }

  const handleCategoryChange = async (val: any) => {
    if(val){
      await setSubCategoryMenuItems((prev: any) =>
          fieldDataRes.current.escalation_sub_categories?.filter((e: any) =>  e.escalation_category_id === val.id)
        )
    }
    
  }
  return (
    <>
      <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mt: 2, mb: 3 }}
            >
              Add Escalation
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={4}>
                <Controller
                  name="client_email"
                  control={control}
                    render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={clientMailOptions}
                      onKeyUp={(e: any) =>
                        handleInputClientEmail(e.target.value)
                      }
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Client Email"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="order_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="order_id"
                      label="Order Id"
                      variant="outlined"
                      helperText={errors.order_id?.message}
                      error={errors.order_id ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="subject_line"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="subject_line"
                      label="Subject Line"
                      variant="outlined"
                      helperText={errors.subject_line?.message}
                      error={errors.subject_line ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="am"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={amMenuItems ?? []}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="AM"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="pc"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={pcMenuItems ?? []}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="PC"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              
              <Grid item xs={4}>
                <Controller
                  name="type"
                  control={control}
                  defaultValue={'1'} // Set default value for this field
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-select-currency"
                      select
                      label="Type"
                      fullWidth
                      helperText={errors.type?.message}
                      error={errors.type ? true : false}
                    >
                       {ESCALATION_TYPE.map(e => {
                        return (<MenuItem key={uuidValue()} value={e.id}>
                        {e.name}
                      </MenuItem>)
                      })}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="category"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={categoryMenuItems ?? []}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      onChange={(e: any, newVal: any) => {
                        field.onChange(newVal)
                        handleCategoryChange(newVal)
                      }}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Category"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="sub_category"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={subCategoryMenuItems ?? []}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.label === value.label
                      }
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub-Category"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
   
              
              <Grid item xs={4}>
                <Controller
                  name="impact"
                  control={control}
                  defaultValue={'1'} // Set default value for this field
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-select-currency"
                      select
                      label="Impact"
                      fullWidth
                      helperText={errors.impact?.message}
                      error={errors.impact ? true : false}
                    >
                       {ESCALATION_IMPACT.map(e => {
                        return (<MenuItem key={uuidValue()} value={e.id}>
                        {e.name}
                      </MenuItem>)
                      })}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="description"
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                      helperText={errors.description?.message}
                      error={errors.description ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
           
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button type="button" variant="outlined" size="medium" onClick={()=>navigate(-1)}>
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default EscalationCreate