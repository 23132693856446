import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  TextField,
  Box,
  Grid,
  MenuItem,
} from '@mui/material'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as uuid from 'uuid'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { BaseResponse } from '../../../../interfaces/ApiResponse'
import AlertComponent, {
  AlertData,
} from '../../../../components/utilities/AlertComponent'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { toast } from 'react-toastify'
import Toaster from '../../../../config/Toaster'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { dateFormat } from '../../../../config/Constant'

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('First name is required').matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
  last_name: Yup.string().required('Last name is required').matches(/^[a-zA-Z0-9 ]+$/, 'Special characters are not allowed'),
  employee_id: Yup.string().required('Employee ID is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),
  password: Yup.string().test({
    name: 'password',
    skipAbsent: true,
    test(value, ctx) {
      const valString = String(value)
      if (valString && valString.length <= 8) {
        return ctx.createError({
          message: 'Password must be at least 8 characters long',
        })
      }
      return true
    },
  }),
  confirm_password: Yup.string().test({
    name: 'confirm_password',
    skipAbsent: true,
    test(value, ctx) {
      const { resolve } = this
      const password = resolve(Yup.ref('password'))
      if (!password) {
        // Password is not provided, no need to validate further
        return true
      }
      // Check if passwords match
      return (
        (password && value === password) ||
        ctx.createError({
          message: 'Password and Confirm-password do not match',
        })
      )
    },
  }),
  role: Yup.string().required('Role is required'),
  department_id: Yup.string().required('Department is required'),
  shift_id: Yup.string().required('Shift is required'),
  reporting_manager_id: Yup.string().required('Reporting manager is required'),
  status: Yup.number()
    .required('Status is required')
    .oneOf([0, 1], 'Invalid status'),
  confirmation_date: Yup.date().nullable(),
})

const UserEdit = observer(() => {
  const navigate = useNavigate()
  const uuidValue = () => uuid.v4()
  const { USER } = useStore()
  const { id } = useParams()
  const { fetchUser, updateUser } = USER
  const {
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      first_name: '',
      last_name: '',
      employee_id: '',
      email: '',
      password: '',
      confirm_password: '',
      role: '',
      department_id: '',
      shift_id: '',
      reporting_manager_id: '',
      status: 1,
      confirmation_date: null,
    },
  })

  const [roleMenuItems, setRoleMenuItems] = useState<any>([])
  const [reportingManagerMenuItems, setReportingManagerMenuItems] = useState<any>([])
  const [departmentMenuItems, setDepartmentMenuItems] = useState<any>([])
  const [shiftMenuItems, setShiftMenuItems] = useState<any>([])
  const [alertMessage, setAlertMessage] = useState<AlertData|null>(null)
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfPassword, setShowConfPassword] = useState<boolean>(false);

  const setInitialFormValues = async (_userData: any) => {
    try{
      await setValue('first_name', _userData?.first_name || '')
      await setValue('last_name', _userData?.last_name || '')
      await setValue('employee_id', _userData?.employee_id || '')
      await setValue('confirmation_date', _userData?.confirmation_date || null)
      await setValue('email', _userData?.email || '')
      await setValue('status', _userData?.status || 0)
      await setValue('role', _userData?.role?.id || '')
      await setValue('department_id', _userData?.department_id || '')
      await setValue('shift_id', _userData?.shift_id || '')
      await setValue('reporting_manager_id', _userData?.reporting_manager_id || '')
    }catch(err: any){
      
    }
    return true
  }

  const fetchUserData = async (_id: string | undefined) => {
    try {
      const resData: any = await fetchUser(id)
      if (resData.error === false) {
        setDepartmentMenuItems(resData?.data?.departments)
        setRoleMenuItems(resData?.data?.roles)
        setReportingManagerMenuItems(resData?.data?.reporting_managers)
        setShiftMenuItems(resData?.data?.shifts)
        await setInitialFormValues(resData?.data?.user)
      } else {
          window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        setAlertMessage({
          severity:'error',
          message: resData.message,
          data: resData.data
        });
         toast.error(resData?.message, Toaster)
      }
    } catch (err: any) {
      console.error(err)
      const errData: BaseResponse = err
        window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        setAlertMessage({
          severity:'error',
          message: errData.message,
          data: errData.data
        });
      toast.error(errData.message, Toaster)
    }
  }

  const onSubmit = async (_data: any) => {
    try {
      if (_data.password === '') {
        delete _data['password']
        delete _data['confirm_password']
      }
      if( _data?.confirmation_date){
        _data.confirmation_date = dayjs(_data?.confirmation_date).format(dateFormat)
      }
      const resData: BaseResponse = await updateUser(id, _data)
      if (resData.error === false) {
        toast.success(resData?.message, Toaster)
        navigate("../list")
      } else {
         window.scrollTo({
            top: 0,
            behavior: 'smooth' 
          });
        setAlertMessage({
          severity:'error',
          message: resData.message,
          data: resData.data
        });
        toast.error(resData?.message, Toaster)
      }
    } catch (err: any) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' 
      });
      const errData: BaseResponse = err
      setAlertMessage({
        severity:'error',
        message: errData.message,
        data: errData.data
      });
      if (errData.data['email']) {
        setError('email', {
          type: 'manual',
          message: errData.data['email'][0],
        })
      }
      if (errData.data['employee_id']) {
        setError('employee_id', {
          type: 'manual',
          message: errData.data['employee_id'][0],
        })
      }
      toast.error(errData?.message, Toaster)
    }
  }

  useEffect(() => {
    fetchUserData(id)
  }, [id])

  return (
    <>
     <Box sx={{mb: 3}}>
      {alertMessage ? <AlertComponent onClose={() => { setAlertMessage(null)}}
          severity = {alertMessage.severity}
          message={alertMessage.message} data={alertMessage.data} /> : null}
      </Box>
      <Card sx={{ maxWidth: '100%' }} variant="outlined">
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ mb: 4, mt: 0 }}
            >
              Edit User
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={6}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="first_name"
                      label="First Name"
                      variant="outlined"
                      helperText={errors.first_name?.message}
                      error={errors.first_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="last_name"
                      label="Last Name"
                      variant="outlined"
                      helperText={errors.last_name?.message}
                      error={errors.last_name ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="employee_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      id="employee_id"
                      label="Employee ID"
                      variant="outlined"
                      helperText={errors.employee_id?.message}
                      error={errors.employee_id ? true : false}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      id="email"
                      label="Email"
                      variant="outlined"
                      helperText={errors.email?.message}
                      error={errors.email ? true : false}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      id="password"
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      variant="outlined"
                      helperText={errors.password?.message}
                      error={errors.password ? true : false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="confirm_password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      {...field}
                      id="confirm_password"
                      label="Confirm Password"
                      type={showConfPassword ? 'text' : 'password'}
                      autoComplete="off"
                      variant="outlined"
                      helperText={errors.confirm_password?.message}
                      error={errors.confirm_password ? true : false}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowConfPassword(!showConfPassword)}
                              edge="end"
                            >
                            {showConfPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                    name={`confirmation_date`}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        inputFormat={dateFormat} // Specify your desired date format
                        disablePast={false}
                        onChange={(newValue: any) => {
                          field.onChange(newValue)
                        }}
                        label="Confirmation Date"
                        renderInput={(params) => (
                          <TextField
                            id="confirmation_date"
                            fullWidth
                            {...params}
                            helperText={errors.confirmation_date?.message}
                            error={errors.confirmation_date ? true : false}
                          />
                        )}
                      />
                    )}
                  />
              </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="department_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      select
                      label="Department"
                      defaultValue={''}
                      fullWidth
                      helperText={errors.department_id?.message}
                      error={errors.department_id ? true : false}
                    >
                      <MenuItem key={uuidValue()} value={''} disabled>
                        Select Department
                      </MenuItem>
                      {departmentMenuItems &&
                        departmentMenuItems.map((dept: any) => (
                          <MenuItem key={uuidValue()} value={dept.id}>
                            {dept.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="reporting_manager_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      select
                      label="Reporting Manager"
                      defaultValue={field.value}
                      fullWidth
                      helperText={errors.reporting_manager_id?.message}
                      error={errors.reporting_manager_id ? true : false}
                    >
                      <MenuItem key={uuidValue()} value={''} disabled>
                        Select Reporting Manager
                      </MenuItem>
                      {reportingManagerMenuItems &&
                        reportingManagerMenuItems.map((e: any) => (
                          <MenuItem key={uuidValue()} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="shift_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      select
                      label="Shift"
                      defaultValue={field.value}
                      fullWidth
                      helperText={errors.shift_id?.message}
                      error={errors.shift_id ? true : false}
                    >
                      <MenuItem key={uuidValue()} value={''} disabled>
                        Select Shift
                      </MenuItem>
                      {shiftMenuItems &&
                        shiftMenuItems.map((e: any) => (
                          <MenuItem key={uuidValue()} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="outlined-role"
                      select
                      label="Role"
                      defaultValue={field.value}
                      fullWidth
                      helperText={errors.role?.message}
                      error={errors.role ? true : false}
                    >
                      {roleMenuItems &&
                        roleMenuItems.map((e: any) => (
                          <MenuItem key={uuidValue()} value={e.id}>
                            {e.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      {...field}
                      id="status"
                      select
                      label="Status"
                      fullWidth
                      onChange={(e) => {
                        field.onChange(e)
                      }}
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                    >
                      <MenuItem key={uuidValue()} disabled value={''}>
                        Select Status
                      </MenuItem>
                      {[{ id : 1, label : "Active" }, { id : 0, label : "Inactive" }].map((status: any) => (
                          <MenuItem key={status.id} value={status.id}>
                            {status.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ml: "10px",mb: "10px"}}>
            <Button type="submit" variant="contained" size="medium">
              Save
            </Button>
            <Button type="button" variant="outlined" size="medium" onClick={() => navigate(-1)}>
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default UserEdit
