import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Box,
  Grid,
  Autocomplete,
} from '@mui/material'
import LoadingButton  from '@mui/lab/LoadingButton'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { observer } from 'mobx-react'
import useStore from '../../../../../../store'
import dayjs from 'dayjs'
import { BaseResponse } from '../../../../../../interfaces/ApiResponse'
import { AlertData } from '../../../../../../components/utilities/AlertComponent'

import { toast } from 'react-toastify'
import Toaster from '../../../../../../config/Toaster'
import { dateTimeFormat } from '../../../../../../config/Constant'

const BacklogEdit = observer(({ close }: any) => {
  const { id } = useParams()
  const { BACKLOG } = useStore()
  const { setupGrid, agGridParams, editingBacklogId, fetchBacklog, updateBacklog } = BACKLOG
  const fieldDataRes = useRef<any>({})
  const [, setAlertMessage] = useState<AlertData|null>(null)
  const [assignToMenuItems, setAssignToMenuItems] = useState<any>([])
  const [templatesMenuItems, setTemplatesMenuItems] = useState<any>([])
  const [backlogTypesMenuItems, setBacklogTypesMenuItems] = useState<any>([])
  const [backlogSubTypesMenuItems, setBacklogSubTypesMenuItems] = useState<any>([])
  const [saving, setSaving] = useState<any>(false);

  const validationSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    order_templates: Yup.array().min(1, 'Please select at least one template'),
    delivery_datetime: Yup.string().required('Delivery Date is required'),
    assign_to: Yup.object({
        label: Yup.string().required('Please select Assign To'),
        id: Yup.number().nullable(),
    }).required('Please select Assign To'),
    order_backlog_type: Yup.object({
        label: Yup.string().required('Please select backlog type'),
        id: Yup.number().nullable(),
    }).required('Please select backlog type'),
    order_backlog_sub_type: Yup.object({
        label: Yup.string().required('Please select backlog sub type'),
        id: Yup.number().nullable(),
    }).required('Please select backlog sub type'),
  })

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: {
      description: '',
      order_templates: [],
      order_backlog_type: { label: '', id: null },
      order_backlog_sub_type: { label: '', id: null },
      delivery_datetime: dayjs().toString(),
      assign_to: { label: '', id: null },
    },
  })

  const setInitialFormValues = async (_backlogData: any) => {
    try{
      setAssignToMenuItems(_backlogData?.users || [])

      fieldDataRes.current = _backlogData
      setTemplatesMenuItems(_backlogData?.createFormListItems?.order_templates || [])
      setBacklogTypesMenuItems(_backlogData?.createFormListItems?.order_backlog_types || [])
      setBacklogSubTypesMenuItems(_backlogData?.createFormListItems?.order_backlog_sub_types || [])

      const backlogData = _backlogData?.order_backlog
      setValue("description", backlogData?.description || "");
      setValue("order_templates", backlogData?.order_templates);
      setValue("order_backlog_type", backlogData?.order_backlog_type);
      setValue("order_backlog_sub_type", backlogData?.order_backlog_sub_type);
      setValue("delivery_datetime", backlogData?.delivery_datetime);
      setValue("assign_to", backlogData?.assign_to);
    }catch(err: any){}
    return true;
  };
  const fetchBacklogData = async (_id: string | undefined) => {
    try {
      const resData: any = await fetchBacklog(_id);
      if (resData.error === false) {
        await setInitialFormValues(resData?.data);
      } else {
        toast.error(resData.message, Toaster);
      }
    } catch (err: any) {
      console.error(err);
      toast.error(err.message, Toaster);
    }
  };

  const handleBacklogTypeChange = async (val: any) => {
    if(val){
      await setBacklogSubTypesMenuItems((prev: any) =>
          fieldDataRes?.current?.createFormListItems?.order_backlog_sub_types?.filter((e: any) =>  e.order_backlog_type_id === val.id)
        )
    }  
  }

  useEffect(() => {
    fetchBacklogData(editingBacklogId);
  }, []);

  const onSubmit = async (_data: any) => {
      try {
        setSaving(true)
        const payload = {
            order_backlog_id : editingBacklogId,
            order_id : id,
            assign_to : _data?.assign_to?.id,
            order_backlog_type_id : _data?.order_backlog_type?.id,
            order_backlog_sub_type_id : _data?.order_backlog_sub_type?.id,
            order_templates:  _data.order_templates.map((item:any) => item.id),
            description : _data?.description,
            delivery_datetime :dayjs(_data.delivery_datetime).format(dateTimeFormat)
        }
        const resData: BaseResponse = await updateBacklog(payload)
        if (resData.error === false) {
          toast.success(resData.message, Toaster)
          close()
          setupGrid(agGridParams)
        } else {
          window.scrollTo({
              top: 0,
              behavior: 'smooth' 
            });
          setAlertMessage({
            severity:'error',
            message: resData.message,
            data: resData.data
          });
          toast.error(resData.message, Toaster)
        }
        setSaving(false)
      } catch (err: any) {
        setSaving(false)
        const errData: BaseResponse = err
         window.scrollTo({
              top: 0,
              behavior: 'smooth' 
            });
        setAlertMessage({
          severity:'error',
          message: errData.message,
          data: errData.data
        });
        toast.error(errData.message, Toaster)
      }
  }

  console.error(errors)

  return (
    <>
      <Box sx={{ mb: 3 }}></Box>
      <Card sx={{ maxWidth: '100%' }} variant='outlined'>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate onKeyDown={(e) => {
                const target = e.target as HTMLElement; // Cast target to HTMLElement
              if (e.key === "Enter" && target.nodeName !== "TEXTAREA") {
                e.preventDefault(); // Prevent form submission when pressing Enter
              }
            }}>
          <CardContent>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={8}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <TextField
                      fullWidth
                      id="description"
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                      error={fieldState.invalid}
                      helperText={fieldState?.error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                  <Controller
                    name={`order_templates`}
                    control={control}
                     render={({ field, fieldState }: any) => (
                      <Autocomplete
                        multiple
                        {...field}
                        isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                        options={templatesMenuItems}
                        onChange={(e: any, newVal: any) => {
                          field.onChange(newVal)
                          return
                        }}
                        getOptionLabel={(option: any) => option.label || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Templates"
                            variant="outlined"
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                          />
                        )}
                      />
                    )}
                  />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="order_backlog_type"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={backlogTypesMenuItems}
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                        handleBacklogTypeChange(newVal)
                      }}
                      isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Backlog Type"
                          variant="outlined"
                          error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="order_backlog_sub_type"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={backlogSubTypesMenuItems}
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Backlog Sub Type"
                          variant="outlined"
                          error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="delivery_datetime"
                    control={control}
                    render={({ field, fieldState }: any) => (
                      <DateTimePicker
                        label="Delivery Date Time"
                        {...field}
                        inputFormat={dateTimeFormat}
                        disablePast={true}
                        onChange={(newValue: any) => {
                          field.onChange(newValue)
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            id="delivery_datetime"
                            fullWidth
                            {...params}
                            error={fieldState.invalid}
                            helperText={fieldState?.error?.message}
                          />
                        )}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="assign_to"
                  control={control}
                  render={({ field, fieldState }: any) => (
                    <Autocomplete
                      {...field}
                      options={assignToMenuItems}
                      onChange={(e, newVal: any) => {
                        field.onChange(newVal)
                      }}
                      isOptionEqualToValue={(option:any, value:any) => option.label === value.label}
                      getOptionLabel={(option: any) => option.label || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assign To"
                          variant="outlined"
                          error={fieldState.invalid}
                            helperText={fieldState?.error?.message || fieldState?.error?.label?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions  sx={{ mb: 1 , ml: 1}}>
              <LoadingButton type="submit" variant="contained" size="medium" loading={saving}>
              Submit For Review
            </LoadingButton>
            <Button
              type="button"
              variant="outlined"
              size="medium"
              onClick={close}
            >
              Cancel
            </Button>
          </CardActions>
        </Box>
      </Card>
    </>
  )
})

export default BacklogEdit
